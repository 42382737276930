import { Alert } from 'antd';

import { DisplayValue } from '../display-value';
import { useApiCache } from '../hooks/use-api-cache';
import { FetchRecordProvider } from '../hooks/use-fetch-record';
import Spinner from '../spinner/spinner';

import ObjectPropertyList from './object-property-list';
import { RecordFields } from './record-fields';

/**
 * Infer display of a record fetch
 *
 * @param props Record fetch API and fields to display.
 *
 * `fields` accepts several different forms:
 *
 *   - '*' character will display all fields and infer how they should be displayed.
 *   - string[] will display only fields listed with inferred display.
 *   - { [field]: (record) => JSX.Element } will use the provided renderers for only the fields with a key name in the object.
 *     Passing  'true' instead of a renderer will use inferred display.
 *     This format also allows for "virtual" properties that don't exist on the table (but will appear in the display)
 *
 * @returns List component
 */
export default function RecordDetails<RecordType extends object>(props: {
    getRecord: () => Promise<RecordType>;
    cacheKey: string;
    fields: RecordFields<RecordType>;
}) {
    const { loading, record, error, fetchRecord, params, setParams } =
        useApiCache(props.getRecord, props.cacheKey);

    if (error) {
        return (
            <Alert
                message="Problem"
                description={<DisplayValue value={error} />}
                type="error"
            />
        );
    }

    if (Array.isArray(record)) {
        return null;
    } else if (typeof record === 'object' && record !== null) {
        return (
            <FetchRecordProvider
                record={record}
                loading={loading}
                fetchRecord={fetchRecord}
                params={params}
                setParams={setParams}
                error={error}
            >
                <ObjectPropertyList
                    values={record}
                    loading={loading}
                    fields={props.fields}
                />
            </FetchRecordProvider>
        );
    } else {
        return <Spinner />;
    }
}

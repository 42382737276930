import { Layout, Space, Typography } from 'antd';
import { useRouteError } from 'react-router';
import { Link } from 'react-router-dom';

import { unwrapError } from '@clh/ui';

export default function ErrorPage() {
    const error = useRouteError();

    // eslint-disable-next-line no-console
    console.error(error);

    return (
        <Layout style={{ backgroundColor: '#fff', marginTop: 10 }}>
            <Space direction="vertical" align="center" size="large">
                <img src={require('./saasquatch-puzzled-midjourney.png')} />
                <Typography.Title level={1}>
                    Woops, there was a problem
                </Typography.Title>
                <p>
                    Reload the page or <Link to={`/logout`}>logout</Link> and
                    try again
                </p>
                <Typography.Text code>
                    Error details: {unwrapError(error)}
                </Typography.Text>
            </Space>
        </Layout>
    );
}

import { Layout, Select, Typography } from 'antd';

import { FacilityControllerGetFacilitiesRequest } from '@clh/api-client';

import { useApiClient } from '../hooks/use-api-client';
import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';
import { DrilldownTable } from '../table';

import Create from './create';
import { Tabs } from './tabs';

const Text = Typography.Text;

export default function Facilities() {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Facilities</h2>
            </Header>
            <Content>
                <DrilldownTable
                    filters={{
                        search: 'search',
                        searchPlaceholder: 'Enter facility name',
                        filters: {
                            includeInactive: {
                                label: 'Include Inactive Facilities',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        placeholder="Include Inactive Facilities"
                                        options={['true', 'false'].map(
                                            (entry) => ({
                                                label: entry,
                                                value: entry,
                                            })
                                        )}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                        },
                    }}
                    buttons={[<Create key="create" />]}
                    getRecords={(
                        params?: FacilityControllerGetFacilitiesRequest
                    ) => {
                        return api.facilityControllerGetFacilities(params);
                    }}
                    cacheKey="facilityControllerGetFacilities"
                    columns={[
                        {
                            dataIndex: 'id',
                            title: 'ID',
                            render: (value: string) => (
                                <Text code>{value.slice(0, 8)}</Text>
                            ),
                        },
                        'name',
                        {
                            title: 'address',
                            render: (record) => (
                                <>
                                    {record.address.line1}{' '}
                                    {record.address.line2 ? (
                                        <>
                                            <br />
                                            {record.address.line2}
                                        </>
                                    ) : null}
                                    <br />
                                    {record.address.city},{' '}
                                    {record.address.state}{' '}
                                    {record.address.postalCode}
                                </>
                            ),
                        },
                        'active',
                    ]}
                >
                    <Tabs />
                </DrilldownTable>
            </Content>
        </Layout>
    );
}

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, SpinProps } from 'antd';
import React from 'react';

type SpinSize = SpinProps['size'];

interface SpinnerProps {
    size?: SpinSize;
}

const sizeMap = {
    small: 16,
    default: 24,
    large: 32,
};

const Spinner: React.FC<SpinnerProps> = ({ size }: SpinnerProps) => (
    <Spin
        indicator={
            <LoadingOutlined
                style={{ fontSize: sizeMap[size || 'default'] }}
                spin
            />
        }
        size={size || 'default'}
    />
);

Spin.setDefaultIndicator(<Spinner />);

export default Spinner;

import { Layout } from 'antd';
import { FC } from 'react';

import { useApiClient } from '@clh/ui';

import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';
import { DrilldownTable } from '../table';

import { Create } from './create';
import { Tabs } from './tabs';

export const NurseLicenseCompacts: FC = () => {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Nurse License Compacts</h2>
            </Header>
            <Content>
                <DrilldownTable
                    buttons={[<Create key="create" />]}
                    filters={{
                        search: 'search',
                        searchPlaceholder: 'Search',
                    }}
                    getRecords={(params) =>
                        api.nurseLicenseCompactControllerListNurseLicenseCompacts(
                            params
                        )
                    }
                    cacheKey="nurseLicenseControllerGetNurseLicenses"
                    columns={['name', 'abbreviation']}
                >
                    <Tabs />
                </DrilldownTable>
            </Content>
        </Layout>
    );
};

import { Form as AntForm } from 'antd';
import { useState } from 'react';

import { useFetchRecordContext } from '../hooks/use-fetch-record';
import { Modal } from '../modal';

import Form from './form';

export default function Create() {
    const [form] = AntForm.useForm();
    const [open, setOpen] = useState(false);

    const { fetchRecord } = useFetchRecordContext();

    const onOk = () => {
        form.submit();
    };

    const onFinish = () => {
        void fetchRecord();
    };

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Create New Facility"
            onOk={onOk}
        >
            <Form form={form} onFinish={onFinish} />
        </Modal>
    );
}

import { DatePicker, Layout, Tag, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { Interval } from 'luxon';

import {
    FacilityDto,
    NurseProfileDto,
    ShiftListItemDto,
    ShiftStatus,
    ShiftTime,
} from '@clh/api-client';
import {
    calculateTotalStipend,
    CostCalculator,
    getEstimatedMinutesToBeWorkedFromShiftLength,
    getShiftDuration,
    toDollarAmount,
} from '@clh/util';

import { getDateStrWithTimezone } from '../helpers';
import { useApiClient } from '../hooks/use-api-client';
import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';
import { DrilldownTable } from '../table';

import { Tabs } from './tabs';

const Text = Typography.Text;

export default function Shifts() {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Shifts</h2>
            </Header>
            <Content>
                <DrilldownTable
                    filters={{
                        search: 'search',
                        searchPlaceholder: 'Enter nurse or facility name',
                        filters: {
                            shiftDate: {
                                label: 'Shift Date',
                                control: ({ onApply }) => (
                                    <DatePicker
                                        onChange={(value: Dayjs | null) => {
                                            if (value) {
                                                onApply(value);
                                            } else {
                                                onApply(value);
                                            }
                                        }}
                                    />
                                ),
                                displayValue: (value: Dayjs) => {
                                    return value.toDate().toLocaleDateString();
                                },
                            },
                        },
                    }}
                    getRecordById={async (id) => {
                        const shifts = await api.shiftControllerListShifts({
                            search: id,
                        });

                        return shifts.result[0];
                    }}
                    getRecords={(params?: any) => {
                        return api.shiftControllerListShifts(params);
                    }}
                    paginated
                    cacheKey="shiftControllerListShifts"
                    columns={[
                        {
                            dataIndex: 'id',
                            title: 'ID',
                            render: (value: string) => (
                                <Typography.Text code>
                                    {value.slice(0, 8)}
                                </Typography.Text>
                            ),
                        },
                        {
                            dataIndex: 'startTime',
                            title: 'Start Time',
                            render: (
                                startTime: Date,
                                shift: ShiftListItemDto
                            ) =>
                                getDateStrWithTimezone(
                                    startTime,
                                    shift.facility.address.timezone
                                ),
                        },
                        {
                            dataIndex: 'endTime',
                            title: 'End Time',
                            render: (endTime: Date, shift: ShiftListItemDto) =>
                                getDateStrWithTimezone(
                                    endTime,
                                    shift.facility.address.timezone
                                ),
                        },
                        {
                            dataIndex: 'facility',
                            title: 'Facility',
                            render: (facility: FacilityDto) => facility.name,
                        },
                        'shiftType',
                        'specialtyCode',
                        {
                            dataIndex: 'status',
                            title: 'Status',
                            render: (status: ShiftStatus) => (
                                <Tag
                                    color={
                                        status === ShiftStatus.Canceled
                                            ? 'error'
                                            : 'success'
                                    }
                                >
                                    {status}
                                </Tag>
                            ),
                        },
                        {
                            dataIndex: 'totalRate',
                            title: 'Cost',
                            render: (
                                totalRate: number,
                                shift: ShiftListItemDto
                            ) => {
                                const duration =
                                    shift.shiftTime === ShiftTime.Custom
                                        ? getEstimatedMinutesToBeWorkedFromShiftLength(
                                              Interval.fromDateTimes(
                                                  shift.startTime,
                                                  shift.endTime
                                              ).toDuration('minutes').minutes
                                          )
                                        : getShiftDuration(shift.shiftTime) *
                                          60;

                                const costs = CostCalculator.getTotalCosts(
                                    totalRate,
                                    shift.platformFeePct,
                                    shift.employerFeePct,
                                    duration,
                                    calculateTotalStipend(
                                        shift.stipends?.filter(
                                            (s) => s.qualified
                                        )
                                    )
                                );

                                return (
                                    <>
                                        {toDollarAmount(costs.total)}
                                        {costs.stipend > 0 && (
                                            <small>
                                                <br />+ stipend
                                            </small>
                                        )}
                                    </>
                                );
                            },
                        },
                        {
                            dataIndex: 'nurseProfile',
                            title: 'Assigned Nurse',
                            render: (
                                nurseProfile: NurseProfileDto | null,
                                shift: ShiftListItemDto
                            ) => (
                                <>
                                    {nurseProfile ? (
                                        <>
                                            {nurseProfile.firstName}{' '}
                                            {nurseProfile.lastName}
                                            <br />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {shift.cancellations
                                        ? shift.cancellations.map(
                                              (cancellation, ix) => (
                                                  <Text
                                                      type="danger"
                                                      key={`${cancellation.shiftId}-${ix}`}
                                                      delete={true}
                                                  >
                                                      {
                                                          cancellation
                                                              .nurseProfile
                                                              .firstName
                                                      }{' '}
                                                      {
                                                          cancellation
                                                              .nurseProfile
                                                              .lastName
                                                      }
                                                      <br />
                                                  </Text>
                                              )
                                          )
                                        : null}
                                </>
                            ),
                        },
                    ]}
                >
                    <Tabs />
                </DrilldownTable>
            </Content>
        </Layout>
    );
}

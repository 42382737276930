import { useContext, useEffect, useState } from 'react';

import { useApiClient } from '@clh/ui';

import {
    PaginatedResult,
    isPaginatedResult,
} from '../../table/paginated-result';

import ApiCacheContext, { ApiCacheContextType } from './api-cache-context';

/**
 * Manages fetching records and setting loading and error states.
 *
 * @param getRecord - API call for fetching record.
 * @param key - Cache key.
 * @param isReady - Whether or not the record should be fetching when this hook loads.
 * @returns React hook
 */
export default function useApiCache<RecordType>(
    getRecord: (
        params?: Record<string, any>
    ) => Promise<RecordType> | Promise<PaginatedResult<RecordType>>,
    key: string,
    isReady: boolean = true
) {
    const api = useApiClient();
    const context =
        useContext<ApiCacheContextType<RecordType>>(ApiCacheContext);
    const [record, setRecord] = useState<RecordType>();
    const [meta, setMeta] = useState<PaginatedResult<RecordType>['meta']>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [params, setParams] = useState<Record<string, any>>();

    const createCachedFunction = <RecordType>(fn: (...args: any[]) => any) => {
        return async (...args: any[]): Promise<RecordType> => {
            const cacheKey = key + JSON.stringify(args);
            const cacheValue = context.get(cacheKey);

            if (cacheValue) {
                return (await cacheValue) as RecordType;
            }

            const pending = fn(...args);

            context.set(cacheKey, pending);

            const result = await pending;

            return result;
        };
    };

    const fetchRecord = async (
        overrideParams?: object,
        skipCache?: boolean
    ) => {
        setLoading(true);
        setError(undefined);

        const cachedGetRecord =
            key && !skipCache
                ? createCachedFunction<RecordType>(getRecord)
                : getRecord;

        try {
            const records = await cachedGetRecord({
                ...params,
                ...overrideParams,
            });

            if (isPaginatedResult(records)) {
                setRecord(records.result);
                setMeta(records.meta);
            } else {
                setRecord(records);
            }
        } catch (e) {
            if (e instanceof Error) {
                let msg = e.message;

                try {
                    msg = JSON.parse(msg);
                } catch (_unparseable) {
                    // Not JSON
                }

                setError(msg);
            } else {
                setError(e);
            }

            setRecord(undefined);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (api && isReady) {
            void fetchRecord();
        }
    }, [api, isReady]);

    return {
        loading,
        record,
        fetchRecord: (overrideParams?: object) =>
            fetchRecord(overrideParams, true),
        error,
        params,
        setParams,
        setRecord,
        meta,
    };
}

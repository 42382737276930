import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Alert, Collapse, Typography } from 'antd';
import { useEffect, useState } from 'react';

import {
    ShiftListItemDto,
    ShiftRatingDto,
    ShiftRatingMetricQuestionsDto,
} from '@clh/api-client';
import { useApiClient, unwrapError } from '@clh/ui';

import { useSelection } from '../../hooks/use-selection';
import { useToast } from '../../hooks/use-toast';
import { FetchRecordTable } from '../../table';

function RatingsTable({
    apiCall,
    metrics,
}: {
    apiCall: () => Promise<Array<ShiftRatingDto>>;
    metrics: ShiftRatingMetricQuestionsDto;
}) {
    const { selection } = useSelection<ShiftListItemDto>();

    return (
        <FetchRecordTable
            size="small"
            rowKey="code"
            pagination={false}
            getRecords={() => apiCall()}
            cacheKey={apiCall.name + selection.id}
            columns={[
                {
                    title: 'Metric',
                    dataIndex: 'metric',
                    render: (metric: string) => (
                        <Collapse
                            bordered={false}
                            items={[
                                {
                                    key: metric,
                                    label: metric,
                                    children: (
                                        <Typography.Paragraph type="secondary">
                                            {metrics.metrics[metric] as string}
                                        </Typography.Paragraph>
                                    ),
                                },
                            ]}
                        />
                    ),
                },
                {
                    title: 'Rating',
                    dataIndex: 'rating',
                    render: (rating: number) => (
                        <>
                            {[...Array(rating)].map((ix) => (
                                <StarFilled key={ix} />
                            ))}
                            {rating < 5 &&
                                [...Array(5 - rating)].map((ix) => (
                                    <StarOutlined key={ix} />
                                ))}
                        </>
                    ),
                },
            ]}
        />
    );
}

export default function RatingsTab() {
    const { selection } = useSelection<ShiftListItemDto>();
    const api = useApiClient();
    const [nurseMetrics, setNurseMetrics] =
        useState<ShiftRatingMetricQuestionsDto>();
    const [facilityMetrics, setFacilityMetrics] =
        useState<ShiftRatingMetricQuestionsDto>();
    const { notification } = useToast();

    const isEnded = selection.endTime < new Date();

    useEffect(() => {
        if (isEnded) {
            Promise.all([
                api
                    ?.shiftRatingMetricControllerGetNurseMetrics()
                    .then((metrics) => setNurseMetrics(metrics)),
                api
                    ?.shiftRatingMetricControllerGetFacilityMetrics()
                    .then((metrics) => setFacilityMetrics(metrics)),
            ]).catch((e) =>
                notification.error({ message: unwrapError(e), duration: 3 })
            );
        }
    }, [api, selection]);

    if (!api) {
        return null;
    }

    if (!isEnded) {
        return (
            <>
                <Alert
                    message={`Ratings only available after the shift ends (${selection.endTime.toLocaleDateString()})`}
                    type="warning"
                />
            </>
        );
    }

    return (
        <>
            <Typography.Title level={4}>Nurse Ratings</Typography.Title>
            {nurseMetrics && (
                <RatingsTable
                    metrics={nurseMetrics}
                    apiCall={function shiftRatingControllerGetNurseRatings() {
                        return api.shiftRatingControllerGetNurseRatings({
                            shiftId: selection.id,
                        });
                    }}
                />
            )}
            <Typography.Title level={4}>Facility Ratings</Typography.Title>
            {facilityMetrics && (
                <RatingsTable
                    metrics={facilityMetrics}
                    apiCall={function shiftRatingControllerGetFacilityRatings() {
                        return api.shiftRatingControllerGetFacilityRatings({
                            shiftId: selection.id,
                        });
                    }}
                />
            )}
        </>
    );
}

import { UploadFile } from 'antd';

import { ListProfileDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { useSelection } from '../../hooks/use-selection';
import Notes from '../../shared/notes';

export default function NotesTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();

    const fetchProfileNotes = async () => {
        const result = await api?.noteControllerGetProfileNotes({
            profileId: selection.id,
        });

        return result || [];
    };

    const onSubmit = async (
        draftNote: string,
        draftNoteFileList: UploadFile[]
    ) => {
        const saved = await api?.noteControllerCreateProfileNote({
            profileId: selection.id,
            profileNoteCreateDto: {
                note: draftNote,
            },
        });

        if (draftNoteFileList.length > 0) {
            await api!.noteControllerUploadAttachments({
                noteId: saved!.id,
                attachments: draftNoteFileList as unknown as File[],
            });
        }
    };

    if (!api) {
        return null;
    }

    return <Notes fetchNotes={fetchProfileNotes} onSubmit={onSubmit} />;
}

import { CheckCircleOutlined } from '@ant-design/icons';
import { Flex, Table, Typography } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';

import { FacilityDto, FacilityNurseDto } from '@clh/api-client';

import { ActionButton } from '../../action-button';
import { useApiClient } from '../../hooks/use-api-client';
import { FetchRecordProvider } from '../../hooks/use-fetch-record';
import { useSelection } from '../../hooks/use-selection';

const Title = ({ children }: PropsWithChildren) => (
    <Typography.Title level={4}>{children}</Typography.Title>
);

export default function NursesTab() {
    const api = useApiClient();
    const { selection } = useSelection<FacilityDto>();
    const [nurses, setNurses] = useState<FacilityNurseDto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();

    const fetchNurses = async () => {
        if (!api) {
            return;
        }

        try {
            setIsLoading(true);
            const result = await api.facilityControllerListNurses({
                facilityId: selection.id,
            });
            setNurses(result);
        } catch (e) {
            setError(e as any);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        void fetchNurses();
    }, [selection.id]);

    return (
        <FetchRecordProvider
            record={nurses}
            fetchRecord={fetchNurses}
            loading={isLoading}
            error={error}
        >
            <div>
                <Title>Nurses</Title>
                <Table
                    rowKey="id"
                    bordered
                    size="small"
                    pagination={false}
                    dataSource={nurses.filter((nurse) => !nurse.blockedAt)}
                    columns={[
                        {
                            title: 'First Name',
                            dataIndex: 'firstName',
                        },
                        {
                            title: 'Last Name',
                            dataIndex: 'lastName',
                        },
                        {
                            title: 'Verified',
                            render: (_, row) => (
                                <>
                                    {row.verifiedFacilities.includes(
                                        selection.id
                                    ) && <CheckCircleOutlined />}
                                </>
                            ),
                        },
                        {
                            title: 'Actions',
                            render: (_, row) => (
                                <Flex gap="small">
                                    {row.verifiedFacilities.includes(
                                        selection.id
                                    ) ? (
                                        <ActionButton
                                            size="small"
                                            successMessage="Nurse unverified"
                                            action={() =>
                                                api?.facilityControllerDeleteNurseVerification(
                                                    {
                                                        profileId: row.id,
                                                        facilityId:
                                                            selection.id,
                                                    }
                                                )
                                            }
                                        >
                                            Unverify
                                        </ActionButton>
                                    ) : (
                                        <ActionButton
                                            size="small"
                                            successMessage="Nurse verified"
                                            action={() =>
                                                api?.facilityControllerVerifyNurse(
                                                    {
                                                        profileId: row.id,
                                                        facilityId:
                                                            selection.id,
                                                    }
                                                )
                                            }
                                        >
                                            Verify
                                        </ActionButton>
                                    )}

                                    <ActionButton
                                        size="small"
                                        successMessage="Nurse blocked"
                                        action={() =>
                                            api?.facilityControllerBlockNurse({
                                                profileId: row.id,
                                                facilityId: selection.id,
                                            })
                                        }
                                    >
                                        Block
                                    </ActionButton>
                                </Flex>
                            ),
                        },
                    ]}
                    loading={isLoading}
                />
            </div>
            <div style={{ marginTop: 20 }}>
                <Title>Blocked Nurses</Title>
                <Table
                    rowKey="id"
                    bordered
                    size="small"
                    pagination={false}
                    dataSource={nurses.filter((nurse) => nurse.blockedAt)}
                    columns={[
                        {
                            title: 'First Name',
                            dataIndex: 'firstName',
                        },
                        {
                            title: 'Last Name',
                            dataIndex: 'lastName',
                        },
                        {
                            title: 'Blocked By',
                            render: (_, row) => (
                                <>
                                    {row.blockedBy} on{' '}
                                    {row.blockedAt?.toLocaleDateString()}
                                </>
                            ),
                        },
                        {
                            title: 'Actions',
                            render: (_, row) => (
                                <>
                                    <ActionButton
                                        size="small"
                                        successMessage="Nurse unblocked"
                                        action={() =>
                                            api?.facilityControllerUnblockNurse(
                                                {
                                                    profileId: row.id,
                                                    facilityId: selection.id,
                                                }
                                            )
                                        }
                                    >
                                        Unblock
                                    </ActionButton>
                                </>
                            ),
                        },
                    ]}
                    loading={isLoading}
                />
            </div>
        </FetchRecordProvider>
    );
}

import { Tabs as AntTabs, Space, TabsProps, Typography } from 'antd';
import { FC } from 'react';

import { NurseLicenseCompactDto } from '@clh/api-client';

import { useSelection } from '../../hooks/use-selection';

import { Jurisdictions } from './jurisdictions';

const tabs: TabsProps['items'] = [
    {
        label: 'Jurisdictions',
        key: 'tab-jurisdictions',
        children: <Jurisdictions />,
    },
];

export const Tabs: FC = () => {
    const { selection } = useSelection<NurseLicenseCompactDto>();

    return (
        <>
            <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
            >
                <Typography.Title level={3}>
                    {selection.name} ({selection.abbreviation})
                </Typography.Title>
            </Space>

            <AntTabs items={tabs} />
        </>
    );
};

import { useAuth0 } from '@auth0/auth0-react';
import { Space } from 'antd';
import { useEffect } from 'react';

import Spinner from '../spinner/spinner';

export default function Logout() {
    const { logout } = useAuth0();

    useEffect(() => {
        void logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }, [logout]);

    return (
        <Space direction="vertical">
            <Spinner />
        </Space>
    );
}

import { PropsWithChildren } from 'react';

import SelectionContext, { SelectionContextType } from './selection-context';

export default function SelectionProvider<RecordType>({
    children,
    ...props
}: SelectionContextType<RecordType> & PropsWithChildren) {
    return (
        <SelectionContext.Provider value={props}>
            {children}
        </SelectionContext.Provider>
    );
}

export var getErrorMessageFromHttpError = function (e, backupMessage) {
    if (backupMessage === void 0) { backupMessage = 'Unknown error occurred'; }
    try {
        if (Array.isArray(e.message)) {
            return e.message;
        }
        return JSON.parse(e.message).message;
    }
    catch (_e) {
        return (e === null || e === void 0 ? void 0 : e.message) || backupMessage;
    }
};

import { useAuth0 } from '@auth0/auth0-react';
import {
    Affix,
    ConfigProvider,
    Layout,
    Row,
    Typography,
    notification,
    theme,
} from 'antd';
import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import { Roles } from '@clh/api-client';
import { useRoles } from '@clh/ui';

import CurrentProfileProvider from './hooks/use-current-profile/current-profile-provider';
import ToastProvider from './hooks/use-toast/toast-provider';
import { Nav } from './nav';
import Spinner from './spinner/spinner';

const { Sider } = Layout;
const { ENVIRONMENT_NAME, GIT_COMMIT } = ENV_CONFIG_VARS;

const App: React.FC = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

    const roles = useRoles();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            void loginWithRedirect();
            return;
        }

        if (
            isAuthenticated &&
            !roles.isLoading &&
            !roles.roles.includes(Roles.PlatformAdministrator)
        ) {
            navigate('/unauthorized');
            return;
        }
    }, [isLoading, isAuthenticated, roles.isLoading, roles.roles]);

    const [toast, toastContextHolder] = notification.useNotification();
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#4b7fb1',
                },
                components: {
                    Layout: {
                        colorBgHeader: '#fff',
                        siderBg: '#fff',
                    },
                    Menu: {
                        itemBg: '#fff',
                        itemColor: '#000',
                        itemHoverBg: '#999',
                        itemSelectedBg: '#1c3f60',
                        subMenuItemBg: '#e0e0e0',
                    },
                },
            }}
        >
            <Layout style={{ minHeight: '100vh' }}>
                <CurrentProfileProvider>
                    {toastContextHolder}
                    <ToastProvider notification={toast}>
                        {!isAuthenticated || roles.isLoading ? (
                            <Row
                                justify="center"
                                align="middle"
                                style={{ minHeight: '100vh' }}
                            >
                                <Spinner size="large" />
                            </Row>
                        ) : (
                            <>
                                <Sider>
                                    <Link to="/">
                                        <h3
                                            style={{
                                                color: colorBgContainer,
                                                textAlign: 'center',
                                                paddingTop: 12,
                                            }}
                                        >
                                            <img
                                                src={require('./saasquatch.png')}
                                                className="saasquatch-logo"
                                            />
                                        </h3>
                                    </Link>
                                    <Nav />
                                    <Affix
                                        style={{
                                            position: 'absolute',
                                            bottom: 5,
                                            width: 200,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography.Text
                                            italic
                                            style={{ color: '#aaa' }}
                                        >
                                            {ENVIRONMENT_NAME} &nbsp;
                                            {GIT_COMMIT}
                                        </Typography.Text>
                                    </Affix>
                                </Sider>
                                <Outlet />
                            </>
                        )}
                    </ToastProvider>
                </CurrentProfileProvider>
            </Layout>
        </ConfigProvider>
    );
};

export default App;

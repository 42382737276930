export function isPaginatedResult<RecordType>(
    records: RecordType | PaginatedResult<RecordType>
): records is PaginatedResult<RecordType> {
    return (
        (records as PaginatedResult<RecordType>).result !== undefined &&
        (records as PaginatedResult<RecordType>).meta !== undefined
    );
}

export interface PaginatedResult<RecordType> {
    result: RecordType;
    meta: {
        currentPage: number;
        previousPage: number | null;
        nextPage: number | null;
        isFirstPage?: boolean;
        isLastPage?: boolean;
        pageCount?: number;
        totalCount?: number;
        startCursor?: string;
        endCursor?: string;
    };
}

import { Layout, Select, Typography } from 'antd';
import { DateTime } from 'luxon';

import {
    CertificationVerificationStatus,
    DhsBackgroundCheckStatus,
} from '@clh/api-client';

import { useApiClient } from '../hooks/use-api-client';
import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';
import { DrilldownTable } from '../table';

import { Tabs } from './tabs';

const Text = Typography.Text;

export default function Profiles() {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Profiles</h2>
            </Header>
            <Content>
                <DrilldownTable
                    filters={{
                        search: 'search',
                        searchPlaceholder: 'Enter name or email',
                        filters: {
                            dhsBackgroundCheckStatus: {
                                label: 'DHS Background Check Status',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="DHS Background Check Status"
                                        options={Object.entries(
                                            DhsBackgroundCheckStatus
                                        ).map((entry) => ({
                                            label: entry[0],
                                            value: entry[1],
                                        }))}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                            certificationVerificationStatus: {
                                label: 'AHA Certifications',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="AHA Certifications"
                                        options={Object.entries(
                                            CertificationVerificationStatus
                                        ).map((entry) => ({
                                            label: entry[0],
                                            value: entry[1],
                                        }))}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                            hasExpiredCertification: {
                                label: 'Has Expired Certifications',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="Has Expired Certifications"
                                        options={['true', 'false'].map(
                                            (entry) => ({
                                                label: entry,
                                                value: entry,
                                            })
                                        )}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                            hasExpiredLicense: {
                                label: 'Has Expired License',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="Has Expired License"
                                        options={['true', 'false'].map(
                                            (entry) => ({
                                                label: entry,
                                                value: entry,
                                            })
                                        )}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                            hasExhaustedAssessment: {
                                label: 'Has Exhausted Assessment',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="Has Exhausted Assessment"
                                        options={['true', 'false'].map(
                                            (entry) => ({
                                                label: entry,
                                                value: entry,
                                            })
                                        )}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                            hasCompleteProfile: {
                                label: 'Has Complete Profile',
                                control: ({ onApply }) => (
                                    <Select
                                        size="small"
                                        style={{ width: 180 }}
                                        placeholder="Has Complete Profile"
                                        options={['true', 'false'].map(
                                            (entry) => ({
                                                label: entry,
                                                value: entry,
                                            })
                                        )}
                                        onChange={onApply}
                                        defaultOpen={true}
                                    ></Select>
                                ),
                            },
                        },
                    }}
                    getRecordById={(id) =>
                        api
                            .profileControllerGetProfile({ profileId: id })
                            .then((r) => ({
                                ...r,
                                isNurse: false,
                                isFacilityAdmin: false,
                            }))
                    }
                    getRecords={(params) => {
                        return api.profileControllerListProfiles(params);
                    }}
                    cacheKey={'profileControllerListProfiles'}
                    columns={[
                        {
                            dataIndex: 'id',
                            title: 'ID',
                            render: (value: string) => (
                                <Text code>{value.slice(0, 8)}</Text>
                            ),
                        },
                        'firstName',
                        'lastName',
                        'email',
                        {
                            dataIndex: 'dateOfBirth',
                            title: 'Birth date',
                            render: (value: Date) => (
                                <>
                                    {DateTime.fromJSDate(value).toLocaleString(
                                        DateTime.DATE_SHORT
                                    )}
                                </>
                            ),
                        },
                        'createdDate',
                        {
                            title: 'Account Type',
                            render: (profile) => profile.roles.join(', '),
                        },
                    ]}
                >
                    <Tabs />
                </DrilldownTable>
            </Content>
        </Layout>
    );
}

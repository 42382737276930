import { Button, ButtonProps } from 'antd';
import { useState } from 'react';

import { unwrapError } from '@clh/ui';

import useFetchRecordContext from '../hooks/use-fetch-record/use-fetch-record-context';
import { useToast } from '../hooks/use-toast';

/**
 * A wrapper around an antd <Button> to manage user interface state when performing actions.
 *
 * @param props - All antd button props and:
 *
 *  - `action`: the function to perform when clicked.
 *  - `success`: the message to display in the toast notification after the action is performed.
 *  - `fetchRecordOnAction`: refetch the associated record if within a FetchRecordContext.
 *
 * @returns Button
 */
export default function ActionButton({
    action,
    successMessage,
    fetchRecordOnAction = true,
    ...props
}: {
    action: (() => Promise<void>) | (() => void);
    successMessage?: string;
    confirm?: string;
    fetchRecordOnAction?: boolean;
} & ButtonProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const fetchRecordContext = useFetchRecordContext();

    const { notification } = useToast();

    const execute = async () => {
        setLoading(true);

        if (props.confirm) {
            if (!confirm(props.confirm)) {
                setLoading(false);
                return;
            }
        }

        try {
            await action();

            notification.success({
                message: successMessage || 'Action succeeded',
                duration: 3,
            });
        } catch (err: any) {
            notification.error({ message: unwrapError(err), duration: 3 });
        } finally {
            setLoading(false);
        }

        if (fetchRecordOnAction && fetchRecordContext) {
            await fetchRecordContext.fetchRecord();
        }
    };

    return (
        <>
            <Button
                {...props}
                onClick={() => void execute()}
                disabled={loading || props.disabled}
            >
                {props.children}
            </Button>
        </>
    );
}

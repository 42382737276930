import { PropsWithChildren } from 'react';

import ToastContext, { ToastContextType } from './toast-context';

export default function ToastProvider({
    children,
    ...props
}: ToastContextType & PropsWithChildren) {
    return (
        <ToastContext.Provider value={{ notification: props.notification }}>
            {children}
        </ToastContext.Provider>
    );
}

import { Card as AntCard, Col, Layout, Row } from 'antd';
import { useEffect, useState } from 'react';

import { AdminLinkDto } from '@clh/api-client';

import { useApiClient } from '../hooks/use-api-client';
import { Header } from '../layout';

const { Meta } = AntCard;

const host = (url: string) => {
    const uri = new URL(url);

    return uri.host;
};

export default function Profiles() {
    const api = useApiClient();

    const [links, setLinks] = useState<AdminLinkDto[]>([]);

    const fetchAdminLinks = async () => {
        const result = await api?.adminControllerGetAdminLinks();

        setLinks(result ?? []);
    };

    useEffect(() => {
        void fetchAdminLinks();
    }, [api]);

    if (!api) {
        return null;
    }

    return (
        <Layout>
            <Header>
                <h2>Humla Health</h2>
            </Header>
            <Row gutter={[32, 32]} style={{ padding: 32, marginRight: 0 }}>
                {links.map((link) => (
                    <Col span={6} key={link.title}>
                        <a href={link.url}>
                            <AntCard hoverable>
                                <Meta
                                    title={link.title}
                                    description={host(link.url)}
                                />
                            </AntCard>
                        </a>
                    </Col>
                ))}
            </Row>
        </Layout>
    );
}

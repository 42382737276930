import { Layout, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { WEBAPP_BASE_URL } = ENV_CONFIG_VARS;

export default function UnauthorizedPage() {
    return (
        <Layout style={{ backgroundColor: '#fff', marginTop: 10 }}>
            <Space direction="vertical" align="center" size="large">
                <img src={require('./saasquatch-puzzled-midjourney.png')} />
                <Typography.Title level={1}>Unauthorized</Typography.Title>
                <p>
                    You may be looking for <a href={WEBAPP_BASE_URL}>this</a> or
                    you may want to{' '}
                    <Link to={`/logout`}>logout and try again</Link>.
                </p>
            </Space>
        </Layout>
    );
}

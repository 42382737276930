import { DateTime } from 'luxon';

export const getDateStrWithTimezone = (
    date: Date,
    timezone?: string | null
) => {
    return DateTime.fromJSDate(
        date,
        timezone
            ? {
                  zone: timezone,
              }
            : undefined
    ).toFormat('M/dd/yyyy, h:mm a ZZZZ');
};

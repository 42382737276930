import { useContext } from 'react';

import ToastContext, { ToastContextType } from './toast-context';

/**
 * Returns the layout toast banner API
 *
 * @returns ToastContext
 */
export default function useToast() {
    const context = useContext<ToastContextType>(ToastContext);

    if (context === undefined) {
        throw new Error('No context provided');
    }

    return context;
}

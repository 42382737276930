import { useContext } from 'react';

import CurrentProfileContext, {
    CurrentProfileContextType,
} from './current-profile-context';

/**
 * Accesses the currently logged in profile
 *
 * @returns CurrentProfileContext
 */
export default function useCurrentProfile() {
    const context = useContext<CurrentProfileContextType>(
        CurrentProfileContext
    );

    if (context === undefined) {
        throw new Error('No context provided');
    }

    return context;
}

import { Table } from 'antd';
import { FC } from 'react';

import { JurisdictionDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { useApiCache } from '../../hooks/use-api-cache';
import { useSelection } from '../../hooks/use-selection';
import Spinner from '../../spinner/spinner';

export const Compacts: FC = () => {
    const api = useApiClient();
    const { selection } = useSelection<JurisdictionDto>();
    const { record: jurisdiction } = useApiCache(
        () =>
            api!.jurisdictionControllerGetJurisdiction({
                abbreviation: selection.abbreviation,
            }),
        `jurisdictionControllerGetJurisdiction/${selection.abbreviation}`,
        !!api
    );

    if (!api || !jurisdiction) {
        return <Spinner />;
    }

    return (
        <>
            <Table
                bordered
                size="small"
                pagination={false}
                dataSource={jurisdiction.nurseLicenseCompacts}
                columns={[
                    { title: 'Name', dataIndex: 'name' },
                    { title: 'Abbreviation', dataIndex: 'abbreviation' },
                ]}
            />
        </>
    );
};

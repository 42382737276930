import { EditFilled } from '@ant-design/icons';
import { Button, Input, InputNumber, Space, Typography } from 'antd';
import { useState } from 'react';

import { FacilityDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';
import { getTextFromQuillDeltas } from '@clh/util';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';
import RecordDetails from '../../record-details/record-details';
import Spinner from '../../spinner';

const Text = Typography.Text;

const EditFacilityName = ({
    name,
    close,
}: {
    name: string;
    close: () => void;
}) => {
    const { selection } = useSelection<FacilityDto>();
    const api = useApiClient();

    const [facilityName, setFacilityName] = useState<string>(name);

    return (
        <Space direction="vertical" style={{ width: '300px' }}>
            Enter new name:
            <Input
                min={0}
                value={facilityName}
                onChange={(e) => setFacilityName(e.currentTarget.value)}
            />
            <Space direction="horizontal">
                <ActionButton
                    disabled={facilityName === name || !facilityName}
                    successMessage="Facility name updated"
                    action={async () => {
                        if (!facilityName) {
                            throw Error('Invalid facility name');
                        }

                        await api?.facilityControllerUpdateFacility({
                            facilityId: selection.id,
                            facilityUpdateDto: {
                                name: facilityName,
                            },
                        });

                        close();
                    }}
                >
                    Update Name
                </ActionButton>
                <Button onClick={close}>Cancel</Button>
            </Space>
        </Space>
    );
};

const EditPlatformFee = ({
    platformFee: defaultPlatformFee,
    close,
}: {
    platformFee: number;
    close: () => void;
}) => {
    const { selection } = useSelection<FacilityDto>();
    const api = useApiClient();

    const [platformFee, setPlatformFee] = useState<number | null>(
        parseInt((defaultPlatformFee * 100).toFixed(2))
    );

    return (
        <Space direction="vertical" style={{ width: '300px' }}>
            Enter platform fee:
            <InputNumber
                min={0}
                value={platformFee}
                onChange={(fee) => setPlatformFee(fee)}
            />
            <Space direction="horizontal">
                <ActionButton
                    disabled={isNaN(platformFee as number)}
                    successMessage="Platform fee updated"
                    action={async () => {
                        if (isNaN(platformFee as number)) {
                            throw Error('Invalid platform fee entered');
                        }

                        const platformFeePct = platformFee! / 100;

                        await api?.facilityControllerUpdatePlatformFee({
                            facilityPlatformFeeDto: { platformFeePct },
                            facilityId: selection.id,
                        });

                        close();
                    }}
                >
                    Update Platform Fee
                </ActionButton>
                <Button onClick={close}>Cancel</Button>
            </Space>
        </Space>
    );
};

export default function InfoTab() {
    const { selection, setSelection } = useSelection<FacilityDto>();
    const api = useApiClient();
    const [editPlatformFee, setEditPlatformFee] = useState<boolean>(false);
    const [editName, setEditName] = useState<boolean>(false);

    if (!selection || !api) {
        return <Spinner />;
    }

    return (
        <RecordDetails
            fields={{
                name: (name) => (
                    <div>
                        {editName ? (
                            <EditFacilityName
                                name={name}
                                close={() => setEditName(false)}
                            />
                        ) : (
                            <>
                                {name}
                                <a onClick={() => setEditName(true)}>
                                    <EditFilled />
                                </a>
                            </>
                        )}
                    </div>
                ),
                id: (id) => (
                    <Text code={true} copyable={true}>
                        {id}
                    </Text>
                ),
                description: (description) => (
                    <>{getTextFromQuillDeltas(description)}</>
                ),
                website: true,
                address: (address) => (
                    <Space direction="vertical">
                        <Text copyable={true}>
                            {address.line1}
                            <br />
                            {address.line2 && (
                                <>
                                    {address.line2}
                                    <br />
                                </>
                            )}
                            {address.city} {address.state} {address.postalCode}
                        </Text>
                        {address.lat && address.lng && (
                            <Text code={true} copyable={true}>
                                {address.lat}, {address.lng}
                            </Text>
                        )}
                    </Space>
                ),
                platformFee: (platformFee) => (
                    <div>
                        {editPlatformFee ? (
                            <EditPlatformFee
                                platformFee={platformFee}
                                close={() => setEditPlatformFee(false)}
                            />
                        ) : (
                            <>
                                {(platformFee * 100).toFixed(2)}%
                                <a onClick={() => setEditPlatformFee(true)}>
                                    <EditFilled />
                                </a>
                            </>
                        )}
                    </div>
                ),
            }}
            cacheKey={`facility-${selection.id}`}
            getRecord={async () => {
                const fees = await api.billingControllerGetFees({
                    facilityId: selection.id,
                });

                const facility = await api.facilityControllerGetFacility({
                    facilityId: selection.id,
                });

                setSelection(facility);

                return {
                    platformFee: fees.platformFeePct,
                    ...facility,
                };
            }}
        />
    );
}

import { Layout, theme } from 'antd';
import React, { PropsWithChildren } from 'react';

const AntContent = Layout.Content;
const AntHeader = Layout.Header;

export const Content: React.FC<PropsWithChildren> = (
    props: PropsWithChildren
) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <AntContent
            style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
            }}
        >
            {props.children}
        </AntContent>
    );
};

export const Header: React.FC<PropsWithChildren> = (
    props: PropsWithChildren
) => {
    return (
        <AntHeader style={{ background: '#000', color: '#fff' }}>
            {props.children}
        </AntHeader>
    );
};

import { useCallback, useState, isValidElement } from 'react';
import { getErrorMessageFromHttpError } from '../../helpers';
export var useError = function (initialState) {
    if (initialState === void 0) { initialState = null; }
    var _a = useState(initialState), error = _a[0], _setError = _a[1];
    var setError = useCallback(function (e, backupMessage) {
        if (e === null ||
            typeof e === 'string' ||
            Array.isArray(e) ||
            isValidElement(e)) {
            _setError(e);
        }
        else {
            setError(getErrorMessageFromHttpError(e, backupMessage));
        }
    }, []);
    return [error, setError];
};

import { DownOutlined } from '@ant-design/icons';
import {
    Dropdown,
    Tabs as AntTabs,
    TabsProps as AntTabsProps,
    Avatar,
    Space,
    Typography,
    Alert,
} from 'antd';
import { useEffect } from 'react';

import { ListProfileDto, Roles } from '@clh/api-client';
import { unwrapError } from '@clh/ui';

import { useApiCache } from '../../hooks/use-api-cache';
import { useApiClient } from '../../hooks/use-api-client';
import { useSelection } from '../../hooks/use-selection';
import { useToast } from '../../hooks/use-toast';
import Spinner from '../../spinner/spinner';

import AssessmentsTab from './assessments';
import BgChecksTab from './bg-checks';
import NotesTab from './notes';
import NursingCredentialsTab from './nursing-credentials';
import ProfileTab from './profile';
import VerifiedFacilitiesTab from './verified-facilities';

const { WEBAPP_BASE_URL } = ENV_CONFIG_VARS;

export default function Tabs() {
    const api = useApiClient();
    const { notification } = useToast();
    const { selection } = useSelection<ListProfileDto>();

    const { loading, record, error } = useApiCache(
        async () => {
            const [
                { roles },
                { permissions },
                { avatar },
                profile,
                nurseProfileCompleteness,
            ] = await Promise.all([
                api!.profileControllerGetUserRoles({
                    profileId: selection.id,
                }),
                api!.profileControllerGetUserPermissions({
                    profileId: selection.id,
                }),
                api!
                    .profileControllerGetAvatar({
                        profileId: selection.id,
                    })
                    .catch(() => ({
                        avatar: null,
                    })),
                api!.profileControllerGetProfile({
                    profileId: selection.id,
                }),
                selection.roles.includes(Roles.Nurse)
                    ? api!.nurseControllerGetProfileCompleteness({
                          profileId: selection.id,
                      })
                    : null,
            ]);

            return {
                ...profile,
                avatar,
                roles,
                nurseProfileCompleteness,
                permissions,
            };
        },
        'profileControllerGetProfile' + selection.id,
        !!api
    );

    useEffect(() => {
        if (error) {
            notification.error({ message: unwrapError(error), duration: 3 });
        }
    }, [error]);

    if (error) {
        return <Alert type="error" message="Profile could not be loaded" />;
    }

    if (loading || !record) {
        return (
            <Space align="center" style={{ width: '100%' }}>
                <Spinner />
            </Space>
        );
    }

    const tabs: AntTabsProps['items'] = [];

    if (record) {
        tabs.push(
            ...[
                {
                    label: 'Profile',
                    key: `tab-profile`,
                    children: <ProfileTab />,
                },
            ]
        );

        if (record.roles.includes(Roles.Nurse)) {
            tabs.push(
                ...[
                    {
                        label: 'Credentials',
                        key: 'tab-nurse-profile',
                        children: <NursingCredentialsTab />,
                    },
                    {
                        label: 'Facilities',
                        key: 'tab-verified-facilities',
                        children: <VerifiedFacilitiesTab />,
                    },
                    {
                        label: 'BG Checks',
                        key: 'tab-bg-checks',
                        children: <BgChecksTab />,
                    },
                    {
                        label: 'Assessments',
                        key: 'assessments',
                        children: <AssessmentsTab />,
                    },
                    {
                        label: 'Notes',
                        key: 'notes',
                        children: <NotesTab />,
                    },
                ]
            );
        }
    }

    return (
        <>
            <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
            >
                <Avatar size="large" src={record.avatar ?? undefined}>
                    {record.firstName[0]} {record.lastName[0]}
                </Avatar>

                <Dropdown
                    menu={{
                        items: [
                            {
                                label: (
                                    <a
                                        href={`${WEBAPP_BASE_URL}/site/nurse/${record.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        View Profile
                                    </a>
                                ),
                                key: 'profile',
                            },
                            {
                                label: (
                                    <a
                                        href={`${WEBAPP_BASE_URL}/site/impersonate/${record.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Impersonate
                                    </a>
                                ),
                                key: 'impersonate',
                            },
                        ],
                    }}
                >
                    <a>
                        <Space>
                            <Typography.Title level={4}>
                                {`${record.firstName} ${record.lastName}`}{' '}
                                <DownOutlined />
                            </Typography.Title>
                        </Space>
                    </a>
                </Dropdown>
            </Space>

            <AntTabs items={tabs} />
        </>
    );
}

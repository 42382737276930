import { Form, Input, Layout } from 'antd';
import { FC, useState } from 'react';

import { useApiClient } from '@clh/ui';

import { ActionButton } from '../action-button';
import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';

// https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd]{8,}/;

export const TestData: FC = () => {
    const api = useApiClient();
    const [baseEmail, setBaseEmail] = useState<string>();
    const [password, setPassword] = useState<string>();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Test Data</h2>
            </Header>
            <Content>
                <p>
                    Create a test nurse, test admin account and two facilities.
                </p>
                <p>
                    foo@humla.com will create two logins with the same password:
                </p>
                <p>
                    <ul>
                        <li>foo+nurse@humla.com</li>
                        <li>foo+admin@humla.com</li>
                    </ul>
                </p>
                <Form layout="vertical">
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true }]}
                    >
                        <Input onChange={(e) => setBaseEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                pattern: passwordRegex,
                                message:
                                    'Password must be at least 8 characters long and at least 3 of the following: 1. Lower case letters (a-z), 2. Upper case letters (A-Z), 3. Numbers (0-9), 4. Special characters (e.g. !@#$%^&*)',
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            pattern={passwordRegex.source}
                            title="at least 8 characters long and at least 3 of the following: 1. Lower case letters (a-z), 2. Upper case letters (A-Z), 3. Numbers (0-9), 4. Special characters (e.g. !@#$%^&*)"
                        />
                    </Form.Item>
                    <Form.Item>
                        <ActionButton
                            action={async () => {
                                await api?.testDataControllerGenerateTestData({
                                    createTestDataDto: {
                                        baseEmail: baseEmail as string,
                                        password: password as string,
                                        nurseCount: 1,
                                        facilityCount: 1,
                                        multiplier: 1,
                                    },
                                });
                            }}
                            disabled={!baseEmail || !password}
                            successMessage="Test data created."
                        >
                            Create Test Users
                        </ActionButton>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

import { Button } from 'antd';
import { useState } from 'react';

import { FacilityDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';

export const DangerTab = () => {
    const [isConfirm, setIsConfirm] = useState(false);
    const api = useApiClient();
    const { selection } = useSelection<FacilityDto>();

    if (!selection.active) {
        return (
            <>
                <ActionButton
                    action={async () => {
                        await api?.facilityControllerReactivateFacility({
                            facilityId: selection.id,
                        });
                    }}
                    successMessage="Facility Reactivated"
                >
                    Reactivate Facility
                </ActionButton>
                <p>
                    <i>
                        *Refresh page to see facility in admin list of active
                        facilities.
                    </i>
                </p>
            </>
        );
    }

    return (
        <>
            {isConfirm ? (
                <ActionButton
                    action={async () => {
                        await api?.facilityControllerDeactivateFacility({
                            facilityId: selection.id,
                        });
                    }}
                    successMessage="Facility Deactivated"
                >
                    Are you sure? Click again to Deactivate Facility
                </ActionButton>
            ) : (
                <Button onClick={() => void setIsConfirm(true)}>
                    Deactivate Facility
                </Button>
            )}
            <p>
                <i>
                    *Facility orientations and shifts will no longer show up in
                    browse pages for nurses. Facility admins will not see
                    facility when they log in. On page refresh Facility will
                    disappear from admin list of facilities.
                </i>
            </p>
        </>
    );
};

import { Drawer } from 'antd';
import React from 'react';

import useSelection from '../hooks/use-selection/use-selection';

export default function Details({ children }: { children: React.ReactNode }) {
    const { selection, setSelection } = useSelection();

    return (
        <Drawer
            width={640}
            placement="right"
            open={!!selection}
            onClose={() => setSelection(undefined)}
            closable={false}
        >
            {!!selection && children}
        </Drawer>
    );
}

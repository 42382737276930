import { Alert, Form as AntForm, FormProps, Input } from 'antd';

import { RegisterPlatformAdministratorAccountDto } from '@clh/api-client';
import { useError } from '@clh/ui';

import { useApiClient } from '../hooks/use-api-client';

const FormItem = AntForm.Item;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

type Props = FormProps & {
    onFinish?: (values: any) => void;
};

export default function Form(props: Props) {
    const api = useApiClient();
    const [error, setError] = useError();

    const onFinish = (values: RegisterPlatformAdministratorAccountDto) => {
        api
            ?.accountControllerRegisterPlatformAdministratorAccount({
                registerPlatformAdministratorAccountDto: values,
            })
            .then(() => {
                if (props.onFinish) {
                    props.onFinish(values);
                }
            })
            .catch((e) => {
                setError(e);
            });
    };

    return (
        <AntForm {...layout} {...props} onFinish={onFinish}>
            {error && <Alert message={error} type="error" />}
            <FormItem
                name={['firstName']}
                label="First Name"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
            <FormItem
                name={['lastName']}
                label="Last Name"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
            <FormItem
                name={['auth0UserId']}
                label="Auth0 User ID"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
        </AntForm>
    );
}

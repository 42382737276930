import { Layout, Typography } from 'antd';

import { useApiClient } from '../hooks/use-api-client';
import { Content, Header } from '../layout';
import Spinner from '../spinner/spinner';
import { FetchRecordTable } from '../table';

import Create from './create';

const Text = Typography.Text;

export default function PlatformAdmins() {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Platform Admins</h2>
            </Header>
            <Content>
                <FetchRecordTable
                    buttons={[<Create key="create" />]}
                    getRecords={() => {
                        return api.platformAdminControllerGetPlatformAdmins();
                    }}
                    cacheKey="platformAdminControllerGetPlatformAdmins"
                    columns={[
                        {
                            dataIndex: 'id',
                            title: 'ID',
                            render: (value: string) => (
                                <Text code>{value.slice(0, 8)}</Text>
                            ),
                        },
                        'firstName',
                        'lastName',
                        'email',
                        'auth0UserId',
                    ]}
                />
            </Content>
        </Layout>
    );
}

import { Layout, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
    return (
        <Layout style={{ backgroundColor: '#fff', marginTop: 10 }}>
            <Space direction="vertical" align="center" size="large">
                <img src={require('./saasquatch-not-found.png')} />
                <Typography.Title level={1}>Not Found</Typography.Title>
                <p>
                    No saasquatch found here.{' '}
                    <Link to={`/`}>Back to home base.</Link>
                </p>
            </Space>
        </Layout>
    );
}

import { Tabs as AntTabs, Space, TabsProps, Typography } from 'antd';
import { FC } from 'react';

import { JurisdictionDto } from '@clh/api-client';

import { useSelection } from '../../hooks/use-selection';

import { Compacts } from './compacts';

const tabs: TabsProps['items'] = [
    {
        label: 'Compacts',
        key: 'tab-compacts',
        children: <Compacts />,
    },
];

export const Tabs: FC = () => {
    const { selection } = useSelection<JurisdictionDto>();

    return (
        <>
            <Space
                direction="vertical"
                align="center"
                style={{ width: '100%' }}
            >
                <Typography.Title level={3}>
                    {selection.name} ({selection.abbreviation})
                </Typography.Title>
            </Space>

            <AntTabs items={tabs} />
        </>
    );
};

import { Modal as AntModal, Button, ModalProps } from 'antd';
import React from 'react';

type Props = ModalProps & {
    title: string;
    setOpen?: (open: boolean) => void;
    onTarget?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function Modal({
    title,
    open,
    setOpen,
    onTarget,
    onOk,
    onCancel,
    ...props
}: Props) {
    function handleTarget(e: React.MouseEvent<HTMLButtonElement>) {
        onTarget?.(e);
        setOpen?.(!open);
    }

    function handleOk(e: React.MouseEvent<HTMLButtonElement>) {
        onOk?.(e);
        setOpen?.(false);
    }

    function handleCancel(e: React.MouseEvent<HTMLButtonElement>) {
        onCancel?.(e);
        setOpen?.(false);
    }

    return (
        <>
            <Button
                type="primary"
                onClick={(e) =>
                    handleTarget(e as React.MouseEvent<HTMLButtonElement>)
                }
            >
                {title}
            </Button>
            <AntModal
                {...props}
                open={open}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                {props.children}
            </AntModal>
        </>
    );
}

import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { AccessTokenProvider, ConfigProvider, RolesProvider } from '@clh/ui';

import { ApiCacheProvider } from './hooks/use-api-cache';
import router from './routes';

import 'antd/dist/reset.css';
import './index.css';

const { AUTH0_CLIENT_ID, AUTH0_TENANT_DOMAIN, AUTH0_AUDIENCE, API_BASE_URL } =
    ENV_CONFIG_VARS;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const render = () => {
    root.render(
        <React.StrictMode>
            <ConfigProvider
                config={{
                    apiAuth0Audience: AUTH0_AUDIENCE,
                    apiBaseUrl: API_BASE_URL,
                    auth0TenantDomain: AUTH0_TENANT_DOMAIN,
                }}
            >
                <Auth0Provider
                    domain={AUTH0_TENANT_DOMAIN}
                    clientId={AUTH0_CLIENT_ID}
                    cacheLocation="localstorage"
                    authorizationParams={{
                        audience: AUTH0_AUDIENCE,
                        redirect_uri: `${window.location.origin}`,
                    }}
                >
                    <AccessTokenProvider
                        audience={AUTH0_AUDIENCE}
                        redirectUri={window.location.origin}
                    >
                        <ApiCacheProvider>
                            <RolesProvider>
                                <RouterProvider router={router} />
                            </RolesProvider>
                        </ApiCacheProvider>
                    </AccessTokenProvider>
                </Auth0Provider>
            </ConfigProvider>
        </React.StrictMode>
    );
};

render();

if (module.hot) {
    module.hot.accept('./routes.tsx', function () {
        render();
    });
}

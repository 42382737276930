import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { ProfileDto } from '@clh/api-client';

import { useApiClient } from '../use-api-client';

import CurrentProfileContext, {
    CurrentProfileContextType,
} from './current-profile-context';

export default function CurrentProfileProvider({
    children,
}: CurrentProfileContextType & PropsWithChildren) {
    const [profile, setProfile] = useState<ProfileDto>();
    const [isLoading, setIsLoading] = useState(true);
    const apiClient = useApiClient();

    useEffect(() => {
        if (apiClient?.isAuthenticated) {
            setIsLoading(true);

            apiClient
                .profileControllerGetCurrentUserProfile()
                .then((result) => {
                    setProfile(result);
                })
                .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.error('Unable to load current profile', e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [apiClient?.isAuthenticated]);

    useEffect(() => {
        if (apiClient?.authenticationFailed) {
            setIsLoading(false);
        }
    }, [apiClient?.authenticationFailed]);

    const contextValue = useMemo(() => {
        return {
            profile,
            isLoading,
        };
    }, [profile, isLoading]);

    return (
        <CurrentProfileContext.Provider value={contextValue}>
            {children}
        </CurrentProfileContext.Provider>
    );
}

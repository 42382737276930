import { useMemo } from 'react';
import { makeApiClient } from '@clh/api-client';
import { apiMiddleware } from '@clh/util';
import { useAccessToken } from './use-access-token';
import { useConfig } from './use-config';
export function useApiClient(accessTokenFromArgs) {
    var config = useConfig();
    var _a = useAccessToken(), accessTokenFromHook = _a.accessToken, error = _a.error, clhSessionId = _a.clhSessionId;
    var accessToken = accessTokenFromArgs || accessTokenFromHook;
    if (!config.apiBaseUrl) {
        throw new Error('apiBaseUrl not provided by ConfigProvider');
    }
    var apiClient = useMemo(function () {
        var apiClient = makeApiClient({
            accessToken: accessToken,
            basePath: config.apiBaseUrl,
            fetchApi: window.fetch,
            middleware: [
                new apiMiddleware.UseSessionHeaderMiddleware(clhSessionId, 'GIT_COMMIT' in window ? window.GIT_COMMIT : ''),
            ],
        });
        apiClient.isAuthenticated = !!accessToken;
        apiClient.authenticationFailed = !!error;
        return apiClient;
    }, [accessToken, error]);
    return apiClient;
}

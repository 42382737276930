import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Layout } from 'antd';
import { DateTime } from 'luxon';

import { PayrollEmployeeCheckDtoStatusEnum } from '@clh/api-client';

import { useApiClient } from '../hooks/use-api-client';
import { Content, Header } from '../layout';
import Spinner from '../spinner';
import { DrilldownTable } from '../table';

import Details from './details';
import { PAYROLL_STATUS_CONFIG } from './payroll-status-config';

export default function Payroll() {
    const api = useApiClient();

    if (!api) {
        return <Spinner />;
    }

    return (
        <Layout>
            <Header>
                <h2>Payroll</h2>
            </Header>
            <Content>
                <DrilldownTable
                    filters={{
                        search: 'search',
                        searchPlaceholder: 'Search by employee name',
                        tabs: {
                            field: 'status',
                            options: [
                                {
                                    label: 'Pending',
                                    value: PayrollEmployeeCheckDtoStatusEnum.Pending,
                                },
                                {
                                    label: 'Processed',
                                    value: PayrollEmployeeCheckDtoStatusEnum.Processed,
                                },
                                {
                                    label: 'All',
                                    value: 'ALL',
                                },
                            ],
                        },
                    }}
                    getRecords={(params) => {
                        return api.payrollControllerGetPaychecks(params);
                    }}
                    cacheKey="payrollControllerGetPaychecks"
                    columns={[
                        {
                            title: 'Employee Name',
                            dataIndex: 'employeeName',
                            key: 'employeeName',
                        },
                        {
                            title: 'Shifts',
                            dataIndex: 'shifts',
                            key: 'shifts',
                            render: (shifts) => shifts.length,
                        },
                        {
                            title: 'Gross Pay',
                            dataIndex: 'grossPay',
                            key: 'grossPay',
                            render: (amount: number) =>
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                }).format(amount / 100),
                        },
                        {
                            title: 'Check Date',
                            dataIndex: 'checkDate',
                            key: 'checkDate',
                            render: (date: Date | null) =>
                                date
                                    ? DateTime.fromJSDate(date, {
                                          zone: 'UTC',
                                      }).toLocaleString(DateTime.DATE_SHORT)
                                    : '',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (status: string) => {
                                const config = PAYROLL_STATUS_CONFIG[
                                    status
                                ] || {
                                    backgroundColor: '#f5f5f5',
                                    text: status,
                                };
                                return (
                                    <span
                                        style={{
                                            backgroundColor:
                                                config.backgroundColor,
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {config.text}
                                    </span>
                                );
                            },
                        },
                        {
                            title: 'Approved',
                            dataIndex: 'isApproved',
                            key: 'isApproved',
                            render: (value: boolean) =>
                                value ? (
                                    <CheckCircleFilled
                                        style={{ color: '#52c41a' }}
                                    />
                                ) : (
                                    <CloseCircleFilled
                                        style={{ color: '#ff4d4f' }}
                                    />
                                ),
                        },
                        {
                            title: 'Created',
                            dataIndex: 'createdAt',
                            key: 'createdAt',
                            render: (date: Date) =>
                                DateTime.fromJSDate(date).toLocaleString(
                                    DateTime.DATE_SHORT
                                ),
                        },
                    ]}
                >
                    <Details />
                </DrilldownTable>
            </Content>
        </Layout>
    );
}

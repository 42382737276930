import { List as AntList } from 'antd';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';

import { RecordFields, displayRecordField } from './record-fields';

export default function ObjectPropertyList<
    T extends Record<string, any>,
    K extends keyof T,
>({
    values,
    loading = false,
    fields,
}: {
    values: T;
    loading?: boolean;
    fields: RecordFields<T>;
}) {
    const [dataSource, setDataSource] = useState<Array<[string, JSX.Element]>>(
        []
    );

    useEffect(() => {
        let display: [string, JSX.Element][] = [];

        const mapDisplayValue = (kv: [K, T[K]]): [string, JSX.Element] => [
            kv[0] as string,
            displayRecordField(fields, kv[0], kv[1]) ?? null,
        ];

        const keyvals = Object.entries(values) as [K, T[K]][];

        if (fields === '*') {
            display = keyvals.map(mapDisplayValue);
        } else if (Array.isArray(fields)) {
            // Re-order to user defined order
            display = fields
                .map((field) => keyvals.find((kv) => kv[0] === field))
                .filter((kv): kv is [K, T[K]] => !!kv)
                .map(mapDisplayValue);
        } else if (typeof fields === 'object') {
            display = Object.keys(fields).map((field) => {
                const entry = keyvals.find((kv) => kv[0] === field);

                return [
                    field,
                    entry
                        ? displayRecordField(fields, field, entry[1])
                        : displayRecordField(fields, field, values),
                ];
            });
        }

        setDataSource(display);
    }, [values, fields]);

    return (
        <AntList
            loading={loading}
            bordered={true}
            renderItem={ListItem}
            dataSource={dataSource}
            style={{ marginBottom: 10 }}
        />
    );
}

function ListItem(item: [string, JSX.Element]) {
    return (
        <AntList.Item>
            <b>{startCase(item[0])}</b>
            {item[1]}
        </AntList.Item>
    );
}

export const PAYROLL_STATUS_CONFIG: Record<
    string,
    { backgroundColor: string; text: string }
> = {
    PENDING: {
        backgroundColor: '#ffd666',
        text: 'Pending',
    },
    PROCESSING: {
        backgroundColor: '#fff7e6',
        text: 'Processing',
    },
    COMPLETED: {
        backgroundColor: '#f6ffed',
        text: 'Completed',
    },
    FAILED: {
        backgroundColor: '#fff1f0',
        text: 'Failed',
    },
    CANCELLED: {
        backgroundColor: '#f5f5f5',
        text: 'Cancelled',
    },
};

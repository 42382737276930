import { useContext } from 'react';

import FetchRecordContext, {
    FetchRecordContextType,
} from './fetch-record-context';

/**
 * Grabs the fetch record hook from the nearest parent that provides it.
 * Useful to re-run record fetch for actions that occur downstream of a component (e.g. list/detail interfaces).
 *
 * @returns FetchRecordContext|undefined - beware! this context may not be set.
 */
export default function useFetchRecordContext<RecordType>() {
    const context =
        useContext<FetchRecordContextType<RecordType>>(FetchRecordContext);

    return context;
}

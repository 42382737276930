import Title from 'antd/es/typography/Title';
import { DateTime } from 'luxon';

import { AssessmentStatus, ListProfileDto } from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';
import { FetchRecordTable } from '../../table';

export default function AssessmentsTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();

    return (
        <>
            <Title level={4}>Specialty Assessments</Title>
            <FetchRecordTable
                size="small"
                pagination={false}
                getRecords={() =>
                    api!.specialtyControllerGetProfileSpecialties({
                        profileId: selection.id,
                    })
                }
                cacheKey={
                    'specialtyControllerGetProfileSpecialties' + selection.id
                }
                columns={[
                    'name',
                    'assessmentStatus',
                    {
                        title: 'Action',
                        dataIndex: 'assessmentIds',
                        render: (assessmentIds) => (
                            <ActionButton
                                action={async () => {
                                    await api?.assessmentsControllerResetAssessments(
                                        {
                                            profileId: selection.id,
                                            resetAssessmentsDto: {
                                                assessmentIds: assessmentIds,
                                            },
                                        }
                                    );
                                }}
                                successMessage="Assessment Reset"
                            >
                                Reset
                            </ActionButton>
                        ),
                    },
                    {
                        title: 'Assessment Expiration Date',
                        dataIndex: 'assessmentAttemptExpiresAt',
                        render: (expiresAt, record) =>
                            record.assessmentStatus ===
                                AssessmentStatus.Pending ||
                            record.assessmentStatus === AssessmentStatus.Expired
                                ? DateTime.fromJSDate(expiresAt).toFormat(
                                      'yyyy-MM-dd'
                                  )
                                : '--',
                    },
                ]}
            />
            <Title level={4}>Regulatory Assessments</Title>
            <FetchRecordTable
                size="small"
                pagination={false}
                getRecords={() =>
                    api!.assessmentsControllerGetRegulatoryAssessments({
                        profileId: selection.id,
                    })
                }
                cacheKey={
                    'assessmentsControllerGetRegulatoryAssessments' +
                    selection.id
                }
                columns={[
                    'name',
                    'assessmentStatus',
                    {
                        title: 'Action',
                        dataIndex: 'assessmentId',
                        render: (assessmentId) => (
                            <ActionButton
                                action={async () => {
                                    await api?.assessmentsControllerResetAssessments(
                                        {
                                            profileId: selection.id,
                                            resetAssessmentsDto: {
                                                assessmentIds: [assessmentId],
                                            },
                                        }
                                    );
                                }}
                                successMessage="Assessment Reset"
                            >
                                Reset
                            </ActionButton>
                        ),
                    },
                    {
                        title: 'Assessment Expiration Date',
                        dataIndex: 'assessmentAttemptExpiresAt',
                        render: (expiresAt, record) =>
                            record.assessmentStatus ===
                                AssessmentStatus.Pending ||
                            record.assessmentStatus === AssessmentStatus.Expired
                                ? DateTime.fromJSDate(expiresAt).toFormat(
                                      'yyyy-MM-dd'
                                  )
                                : '--',
                    },
                ]}
            />
            <ActionButton
                style={{ marginTop: 10 }}
                action={async () =>
                    api?.assessmentsControllerSyncRelias({
                        profileId: selection.id,
                    })
                }
                successMessage="Assessments synced"
            >
                Sync Assessments from Relias
            </ActionButton>
        </>
    );
}

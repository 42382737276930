import { useContext } from 'react';

import SelectionContext, { SelectionContextType } from './selection-context';

/**
 * Accesses the selected record from the nearest parent that provides it.
 *
 * @returns SelectionContext
 */
export default function useSelection<RecordType>() {
    const context =
        useContext<SelectionContextType<RecordType>>(SelectionContext);

    if (context === undefined) {
        throw new Error('No context provided');
    }

    return context;
}

var _a;
import { State } from '@clh/api-client';
export var StateAbbrToDisplayName = (_a = {},
    _a[State.Al] = 'Alabama',
    _a[State.Ak] = 'Alaska',
    _a[State.Az] = 'Arizona',
    _a[State.Ar] = 'Arkansas',
    _a[State.Ca] = 'California',
    _a[State.Co] = 'Colorado',
    _a[State.Ct] = 'Connecticut',
    _a[State.De] = 'Delaware',
    _a[State.Dc] = 'District of Columbia',
    _a[State.Fl] = 'Florida',
    _a[State.Ga] = 'Georgia',
    _a[State.Hi] = 'Hawaii',
    _a[State.Id] = 'Idaho',
    _a[State.Il] = 'Illinois',
    _a[State.In] = 'Indiana',
    _a[State.Ia] = 'Iowa',
    _a[State.Ks] = 'Kansas',
    _a[State.Ky] = 'Kentucky',
    _a[State.La] = 'Louisiana',
    _a[State.Me] = 'Maine',
    _a[State.Md] = 'Maryland',
    _a[State.Ma] = 'Massachusetts',
    _a[State.Mi] = 'Michigan',
    _a[State.Mn] = 'Minnesota',
    _a[State.Ms] = 'Mississippi',
    _a[State.Mo] = 'Missouri',
    _a[State.Mt] = 'Montana',
    _a[State.Ne] = 'Nebraska',
    _a[State.Nv] = 'Nevada',
    _a[State.Nh] = 'New Hampshire',
    _a[State.Nj] = 'New Jersey',
    _a[State.Nm] = 'New Mexico',
    _a[State.Ny] = 'New York',
    _a[State.Nc] = 'North Carolina',
    _a[State.Nd] = 'North Dakota',
    _a[State.Oh] = 'Ohio',
    _a[State.Ok] = 'Oklahoma',
    _a[State.Or] = 'Oregon',
    _a[State.Pa] = 'Pennsylvania',
    _a[State.Pr] = 'Puerto Rico',
    _a[State.Ri] = 'Rhode Island',
    _a[State.Sc] = 'South Carolina',
    _a[State.Sd] = 'South Dakota',
    _a[State.Tn] = 'Tennessee',
    _a[State.Tx] = 'Texas',
    _a[State.Ut] = 'Utah',
    _a[State.Vt] = 'Vermont',
    _a[State.Va] = 'Virginia',
    _a[State.Wa] = 'Washington',
    _a[State.Wv] = 'West Virginia',
    _a[State.Wi] = 'Wisconsin',
    _a[State.Wy] = 'Wyoming',
    _a);

import isBoolean from 'lodash/isBoolean';

import { DisplayValue } from '../display-value';

type ObjectMapOfRenderers<RecordType> = Partial<
    Record<keyof RecordType, ((val: any) => JSX.Element) | true>
>;

export type RecordFields<RecordType> =
    | keyof RecordType[]
    | '*'
    | ObjectMapOfRenderers<RecordType>;

export function displayRecordField<
    RecordType extends Record<string, any>,
    K extends keyof RecordType,
>(
    fields: RecordFields<RecordType>,
    key: K,
    value: RecordType[K] | RecordType
): JSX.Element {
    if (typeof fields === 'object') {
        const render = fields[key];

        if (isBoolean(render)) {
            return <DisplayValue value={value} />;
        }

        if (render) {
            return render(value);
        }
    }

    return <DisplayValue value={value} />;
}

import { Select, Space } from 'antd';
import { useEffect, useState } from 'react';

import { FacilityDto, ListProfileDto } from '@clh/api-client';

import { ActionButton } from '../../action-button';
import { useApiClient } from '../../hooks/use-api-client';
import { useSelection } from '../../hooks/use-selection';
import { FetchRecordTable } from '../../table/table';

export default function VerifiedFacilitiesTab() {
    const api = useApiClient();
    const { selection } = useSelection<ListProfileDto>();
    const [facilities, setFacilities] = useState<FacilityDto[]>();
    const [facilityToAdd, setFacilityToAdd] = useState<string>();

    const fetchFacilities = async () => {
        const result = await api?.facilityControllerGetFacilities();
        setFacilities(result);
    };

    useEffect(() => {
        void fetchFacilities();
    }, [api]);

    return (
        <>
            <FetchRecordTable
                bordered
                size="small"
                pagination={false}
                getRecords={() =>
                    api!.profileControllerGetProfileVerifiedFacilities({
                        profileId: selection.id,
                    })
                }
                cacheKey={
                    'profileControllerGetProfileVerifiedFacilities' +
                    selection.id
                }
                columns={[
                    {
                        title: 'Facility',
                        dataIndex: 'name',
                    },
                    {
                        title: 'Action',
                        render: (val, record) => (
                            <ActionButton
                                action={() =>
                                    api?.facilityControllerDeleteNurseVerification(
                                        {
                                            profileId: selection.id,
                                            facilityId: record.id,
                                        }
                                    )
                                }
                                successMessage={'Facility verification removed'}
                            >
                                Remove
                            </ActionButton>
                        ),
                    },
                ]}
            >
                <Space.Compact block style={{ marginTop: 10 }}>
                    <Select
                        showSearch
                        placeholder="Select Facility"
                        optionFilterProp="children"
                        style={{ width: '50%' }}
                        onChange={(id) => setFacilityToAdd(id)}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        options={facilities?.map((facility) => ({
                            label: facility.name,
                            value: facility.id,
                        }))}
                    />
                    <ActionButton
                        action={async () => {
                            if (facilityToAdd) {
                                await api?.facilityControllerVerifyNurse({
                                    facilityId: facilityToAdd,
                                    profileId: selection.id,
                                });
                            }
                        }}
                        disabled={!facilityToAdd}
                        successMessage="Facility verification added"
                    >
                        Add
                    </ActionButton>
                </Space.Compact>
            </FetchRecordTable>
        </>
    );
}

import { Space, Input } from 'antd';
import { useState } from 'react';

import { FacilityDto } from '@clh/api-client';

import { ActionButton } from '../../action-button';
import { useApiClient } from '../../hooks/use-api-client';
import { useSelection } from '../../hooks/use-selection';
import { FetchRecordTable } from '../../table';

const { WEBAPP_AUTH0_CLIENT_ID } = ENV_CONFIG_VARS;

export default function MembersTab() {
    const api = useApiClient();
    const { selection } = useSelection<FacilityDto>();
    const [inviteEmail, setInviteEmail] = useState<string>('');

    return (
        <>
            <FetchRecordTable
                bordered
                size="small"
                pagination={false}
                getRecords={() =>
                    api!.facilityControllerListMembers({
                        facilityId: selection.id,
                    })
                }
                cacheKey={'profileControllerListProfiles' + selection.id}
                columns={[
                    'firstName',
                    'lastName',
                    'email',
                    {
                        title: 'Action',
                        dataIndex: 'id',
                        render: (id) => (
                            <ActionButton
                                action={async () => {
                                    await api?.facilityControllerDeleteFacilityMember(
                                        {
                                            facilityId: selection.id,
                                            profileId: id,
                                        }
                                    );
                                }}
                                successMessage="Member removed"
                            >
                                Remove
                            </ActionButton>
                        ),
                    },
                ]}
            />
            <div style={{ padding: '10px 0' }}>
                <b>Invite new member:</b>
            </div>
            <Space.Compact block>
                <Input
                    type="email"
                    onChange={(e) => setInviteEmail(e.target.value)}
                    placeholder="Enter email address"
                />
                <ActionButton
                    action={async () =>
                        api?.facilityControllerInviteUser({
                            facilityId: selection.id,
                            inviteCreateDto: {
                                emails: [inviteEmail],
                                clientId: WEBAPP_AUTH0_CLIENT_ID,
                            },
                        })
                    }
                    disabled={!inviteEmail.length}
                    successMessage="Facility membership invite sent"
                >
                    Send Invite
                </ActionButton>
            </Space.Compact>
        </>
    );
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useReducer } from 'react';
import { AccessTokenContext, } from './access-token-context';
import { CLH_SESSION_ID } from './helpers';
function reducer(state, action) {
    switch (action.type) {
        case 'ACCESS_TOKEN_GRANTED':
            return __assign(__assign({}, state), { accessToken: action.accessToken, isLoading: false });
        case 'ERROR':
            return __assign(__assign({}, state), { error: action.error, isLoading: false });
        default:
            return state;
    }
}
export var AccessTokenProvider = function (props) {
    var audience = props.audience, redirectUri = props.redirectUri, children = props.children;
    var _a = useReducer(reducer, {
        accessToken: undefined,
        isLoading: true,
    }), state = _a[0], dispatch = _a[1];
    var _b = useAuth0(), user = _b.user, getAccessTokenSilently = _b.getAccessTokenSilently, isAuthenticated = _b.isAuthenticated, loginWithRedirect = _b.loginWithRedirect, isLoading = _b.isLoading;
    useEffect(function () {
        if (isLoading) {
            return;
        }
        if (!isAuthenticated) {
            dispatch({ type: 'ERROR', error: new Error('Unauthenticated') });
            return;
        }
        void (function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getAccessTokenSilently({
                                authorizationParams: {
                                    audience: audience,
                                },
                            })];
                    case 1:
                        accessToken = _a.sent();
                        dispatch({ type: 'ACCESS_TOKEN_GRANTED', accessToken: accessToken });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        dispatch({ type: 'ERROR', error: error_1 });
                        sessionStorage.setItem('pathname', window.location.pathname);
                        sessionStorage.setItem('search', window.location.search);
                        sessionStorage.setItem('hash', window.location.hash);
                        void loginWithRedirect({
                            authorizationParams: {
                                redirect_uri: redirectUri,
                            },
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); })();
    }, [audience, getAccessTokenSilently, isAuthenticated, isLoading]);
    var contextValue = useMemo(function () {
        return {
            auth0UserId: user === null || user === void 0 ? void 0 : user.sub,
            accessToken: state.accessToken,
            error: state.error,
            isLoading: state.isLoading,
            clhSessionId: CLH_SESSION_ID,
        };
    }, [state]);
    return (_jsx(AccessTokenContext.Provider, { value: contextValue, children: children }));
};

import { Select, Space, Table } from 'antd';
import { FC, useState } from 'react';

import {
    NurseLicenseCompactDto,
    NurseLicenseCompactUpdateDto,
} from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import { useApiCache } from '../../hooks/use-api-cache';
import { useSelection } from '../../hooks/use-selection';
import Spinner from '../../spinner/spinner';

export const Jurisdictions: FC = () => {
    const [selectedJurisdiction, setSelectedJurisdiction] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [deletingJurisdiction, setDeletingJurisdiction] = useState('');
    const api = useApiClient();
    const { selection } = useSelection<NurseLicenseCompactDto>();
    const { record: allJurisdictions } = useApiCache(
        () => api!.jurisdictionControllerListJurisdictions(),
        'jurisdictionControllerListJurisdictions',
        !!api
    );
    const { record: compact, fetchRecord: fetchCompact } = useApiCache(
        () =>
            api!.nurseLicenseCompactControllerGetNurseLicenseCompact({
                id: selection.id,
            }),
        `nurseLicenseCompactControllerGetNurseLicenseCompact/${selection.id}`,
        !!api
    );

    if (!api || !allJurisdictions || !compact) {
        return <Spinner />;
    }

    async function handleAddJurisdictionClick() {
        const newJurisdictions = [
            ...compact!.jurisdictions.map((j) => j.abbreviation),
            selectedJurisdiction,
        ];
        const newCompact: NurseLicenseCompactUpdateDto = {
            id: '',
            name: compact!.name,
            abbreviation: compact!.abbreviation,
            jurisdictions: newJurisdictions,
        };

        setIsSaving(true);

        try {
            await api!.nurseLicenseCompactControllerUpdateNurseLicenseCompact({
                id: compact!.id,
                nurseLicenseCompactUpdateDto: newCompact,
            });
            setSelectedJurisdiction('');
            await fetchCompact();
        } finally {
            setIsSaving(false);
        }
    }

    async function handleRemoveJurisdictionClick(abbreviation: string) {
        const newJurisdictions = compact!.jurisdictions
            .map((j) => j.abbreviation)
            .filter((j) => j !== abbreviation);
        const newCompact: NurseLicenseCompactUpdateDto = {
            id: '',
            name: compact!.name,
            abbreviation: compact!.abbreviation,
            jurisdictions: newJurisdictions,
        };

        setDeletingJurisdiction(abbreviation);

        try {
            await api!.nurseLicenseCompactControllerUpdateNurseLicenseCompact({
                id: compact!.id,

                nurseLicenseCompactUpdateDto: newCompact,
            });
            setSelectedJurisdiction('');
            await fetchCompact();
        } finally {
            setDeletingJurisdiction('');
        }
    }

    const selectJurisdictions = allJurisdictions
        .filter(
            (j) =>
                !compact.jurisdictions
                    .map((j) => j.abbreviation)
                    .includes(j.abbreviation)
        )
        .map((j) => ({
            label: `${j.name} (${j.abbreviation})`,
            value: j.abbreviation,
        }));

    return (
        <>
            <Space direction="vertical" style={{ display: 'flex' }}>
                <Space.Compact block>
                    <Select
                        options={selectJurisdictions}
                        style={{ width: '100%' }}
                        value={selectedJurisdiction}
                        onChange={(value) => setSelectedJurisdiction(value)}
                    />
                    <ActionButton
                        loading={isSaving}
                        action={handleAddJurisdictionClick}
                        successMessage="Jurisdiction added"
                    >
                        Add Jurisdiction
                    </ActionButton>
                </Space.Compact>

                <Table
                    bordered
                    size="small"
                    pagination={false}
                    dataSource={compact.jurisdictions}
                    columns={[
                        { title: 'Name', dataIndex: 'name' },
                        { title: 'Abbreviation', dataIndex: 'abbreviation' },
                        {
                            title: 'Action',
                            dataIndex: 'abbreviation',
                            render: (abbreviation) => (
                                <ActionButton
                                    loading={
                                        deletingJurisdiction === abbreviation
                                    }
                                    action={() =>
                                        handleRemoveJurisdictionClick(
                                            abbreviation
                                        )
                                    }
                                    successMessage="Jurisdiction removed"
                                >
                                    Remove
                                </ActionButton>
                            ),
                        },
                    ]}
                />
            </Space>
        </>
    );
};

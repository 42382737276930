import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Input, Segmented, Space } from 'antd';
import { ReactNode, useState } from 'react';

import useFetchRecordContext from '../hooks/use-fetch-record/use-fetch-record-context';

type FilterControl = (props: { onApply: (value: any) => void }) => JSX.Element;

export interface FilterOptions {
    search?: string;
    searchPlaceholder?: string;
    filters?: {
        [name: string]: {
            label: string;
            control: FilterControl;
            displayValue?: (value: any) => ReactNode;
        };
    };
    tabs?: {
        field: string;
        options: {
            label: string;
            value: string;
        }[];
    };
}

enum FilterStates {
    Closed,
    Open,
    Applied,
}

function Filter(props: {
    name: string;
    label: string;
    control: FilterControl;
    onApply: (value: any) => void;
    displayValue?: (value: any) => ReactNode;
}) {
    const [filterState, setFilterState] = useState<FilterStates>(
        FilterStates.Closed
    );
    const [filterValue, setFilterValue] = useState<ReactNode>();

    const ControlEl = props.control;

    return (
        <>
            {filterState === FilterStates.Closed && (
                <Button
                    size="small"
                    onClick={() => setFilterState(FilterStates.Open)}
                >
                    {props.label}
                </Button>
            )}
            {filterState === FilterStates.Open && (
                <ControlEl
                    onApply={(value) => {
                        props.onApply(value);

                        if (props.displayValue) {
                            setFilterValue(props.displayValue(value));
                        } else {
                            setFilterValue(value);
                        }

                        setFilterState(FilterStates.Applied);
                    }}
                />
            )}
            {filterState === FilterStates.Applied && (
                <Button
                    icon={<CloseCircleFilled />}
                    size="small"
                    type="primary"
                    onClick={() => {
                        props.onApply(undefined);
                        setFilterValue(undefined);
                        setFilterState(FilterStates.Closed);
                    }}
                >
                    {props.label}: <b>{filterValue}</b>
                </Button>
            )}
        </>
    );
}

export function Filters(props: FilterOptions) {
    const { loading, fetchRecord, setParams, params } = useFetchRecordContext();

    const setFilter = (key: string, value: any) => {
        const newParams = {
            ...params,
            [key]: value,
            page: 1,
        };

        if (setParams) {
            setParams(newParams);
        }

        // eslint-disable-next-line no-console
        fetchRecord(newParams).catch((e) => console.error(e));
    };

    return (
        <>
            {props.search && (
                <Space.Compact>
                    <Input.Search
                        style={{ width: 300 }}
                        disabled={!!loading}
                        placeholder={props.searchPlaceholder}
                        allowClear
                        onSearch={(value) => {
                            setFilter(props.search!, value);
                        }}
                    />
                </Space.Compact>
            )}

            {props.filters && (
                <Space
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    Filters:
                    {Object.entries(props.filters).map((filter) => (
                        <Filter
                            key={`filter-${filter[0]}`}
                            name={filter[0]}
                            {...filter[1]}
                            onApply={(value) => setFilter(filter[0], value)}
                        />
                    ))}
                </Space>
            )}

            {props.tabs && (
                <Segmented
                    options={props.tabs.options}
                    onChange={(value) => setFilter(props.tabs!.field, value)}
                />
            )}
        </>
    );
}

import { Alert, Form as AntForm, FormProps, Input, Select } from 'antd';

import { FacilityCreateDto } from '@clh/api-client';
import { StateAbbrToDisplayName, useError } from '@clh/ui';

import { useApiClient } from '../hooks/use-api-client';

const FormItem = AntForm.Item;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

type Props = FormProps & {
    onFinish?: (values: any) => void;
};

export default function Form(props: Props) {
    const api = useApiClient();
    const [error, setError] = useError();

    const onFinish = (values: FacilityCreateDto) => {
        api
            ?.facilityControllerCreateFacility({
                facilityCreateDto: values,
            })
            .then(() => {
                if (props.onFinish) {
                    props.onFinish(values);
                }
            })
            .catch((e) => {
                setError(e);
            });
    };

    return (
        <AntForm {...layout} {...props} onFinish={onFinish}>
            {error && <Alert message={error} type="error" />}
            <FormItem name="name" label="Name" rules={[{ required: true }]}>
                <Input />
            </FormItem>
            <FormItem name="description" label="Description">
                <Input />
            </FormItem>
            <FormItem
                name={['address', 'line1']}
                label="Address"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
            <FormItem
                name={['address', 'line2']}
                wrapperCol={{ offset: 6, span: 14 }}
            >
                <Input />
            </FormItem>
            <FormItem
                name={['address', 'city']}
                label="City"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
            <FormItem
                name={['address', 'state']}
                label="State"
                rules={[
                    {
                        required: true,
                        max: 2,
                    },
                ]}
            >
                <Select
                    style={{ width: 120 }}
                    options={Object.entries(StateAbbrToDisplayName).map(
                        ([value, label]) => ({
                            value,
                            label,
                        })
                    )}
                />
            </FormItem>

            <FormItem
                name={['address', 'postalCode']}
                label="Zip"
                rules={[{ required: true }]}
            >
                <Input />
            </FormItem>
            <FormItem name="website" label="Website">
                <Input />
            </FormItem>
        </AntForm>
    );
}

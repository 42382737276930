import { Alert, Form as AntForm, FormProps, Input } from 'antd';
import { FC } from 'react';

import { useError } from '@clh/ui';

type P = FormProps & {
    error?: ReturnType<typeof useError>[0];
};

export const Form: FC<P> = ({ error, ...props }) => {
    return (
        <AntForm {...props} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            {error && <Alert message={error} type="error" />}

            <AntForm.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input autoFocus />
            </AntForm.Item>
            <AntForm.Item
                name="abbreviation"
                label="Abbreviation"
                rules={[{ required: true, min: 2 }]}
            >
                <Input />
            </AntForm.Item>
        </AntForm>
    );
};

import { FileAddOutlined } from '@ant-design/icons';
import {
    Button,
    DatePicker,
    Form,
    Select,
    Space,
    Typography,
    Upload,
    UploadFile,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
    BackgroundCheckPackageDto,
    DhsBackgroundCheckStatus,
    DhsBackgroundCheckStatusDto,
    ListProfileDto,
} from '@clh/api-client';
import { useApiClient } from '@clh/ui';

import { ActionButton } from '../../action-button';
import { useSelection } from '../../hooks/use-selection';
import Spinner from '../../spinner';
import { FetchRecordTable } from '../../table';

function CheckrBackgroundChecks() {
    const api = useApiClient();

    const { selection } = useSelection<ListProfileDto>();
    const [backgroundCheckPackages, setBackgroundCheckPackages] =
        useState<BackgroundCheckPackageDto[]>();
    const [selectedPackage, setSelectedPackage] = useState<string>();

    const fetchBackgroundCheckPackages = async () => {
        const result = await api?.backgroundCheckPackageControllerGetPackages();

        setBackgroundCheckPackages(result);
    };

    useEffect(() => {
        void fetchBackgroundCheckPackages();
    }, [api]);

    if (!api) {
        return null;
    }

    return (
        <>
            <Typography.Title level={4}>
                Checkr Background Checks
            </Typography.Title>
            <FetchRecordTable
                size="small"
                rowKey="code"
                pagination={false}
                getRecords={async () => {
                    const bgChecks =
                        await api.backgroundCheckControllerGetBackgroundChecks({
                            profileId: selection.id,
                        });

                    return bgChecks;
                }}
                cacheKey={
                    'BackgroundCheckControllerGetBackgroundChecks' +
                    selection.id
                }
                columns={[
                    'packageName',
                    'status',
                    {
                        title: 'Action',
                        render: (val, record) => (
                            <ActionButton
                                action={() =>
                                    api?.backgroundCheckControllerCancelBackgroundCheck(
                                        {
                                            cancelBackgroundCheckDto: {
                                                backgroundCheckId: record.id,
                                            },
                                        }
                                    )
                                }
                                successMessage={'Background check removed'}
                            >
                                Remove
                            </ActionButton>
                        ),
                    },
                ]}
            >
                <Space.Compact
                    block
                    style={{ marginTop: 10, marginBottom: 10 }}
                >
                    <Select
                        showSearch
                        placeholder="Select Checkr Package"
                        optionFilterProp="children"
                        style={{ width: '50%' }}
                        onChange={(id) => setSelectedPackage(id)}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        options={backgroundCheckPackages?.map((pkg) => ({
                            label: pkg.name,
                            value: pkg.id,
                        }))}
                    />
                    <ActionButton
                        action={async () => {
                            if (selectedPackage) {
                                await api?.backgroundCheckControllerSubmitBackgroundCheck(
                                    {
                                        submitBackgroundCheckDto: {
                                            profileId: selection.id,
                                            backgroundCheckPackageId:
                                                selectedPackage,
                                        },
                                    }
                                );
                            }
                        }}
                        disabled={!selectedPackage}
                        successMessage="Invited to Checkr Package"
                    >
                        Submit Invite
                    </ActionButton>
                </Space.Compact>
            </FetchRecordTable>
            <ActionButton
                action={async () => {
                    await api?.backgroundCheckControllerSyncBackgroundCheckStatus(
                        {
                            profileId: selection.id,
                        }
                    );

                    await fetchBackgroundCheckPackages();
                }}
                successMessage="Synced Checkr Background Checks"
            >
                Sync Checkr Background Checks
            </ActionButton>
        </>
    );
}

function DhsBackgroundCheck() {
    const api = useApiClient();

    const { selection } = useSelection<ListProfileDto>();
    const [dhsBackgroundCheckStatus, setDhsBackgroundCheckStatus] =
        useState<DhsBackgroundCheckStatusDto>();
    const [dhsClearanceLetter, setDhsClearanceLetter] =
        useState<UploadFile[]>();
    const [dhsRegistryCheckResult, setDhsRegistryCheckResult] =
        useState<UploadFile[]>();
    const [isUploading, setIsUploading] = useState<
        Record<'DHS_CLEARANCE_LETTER' | 'DHS_REGISTRY_CHECK_RESULT', boolean>
    >({ DHS_CLEARANCE_LETTER: false, DHS_REGISTRY_CHECK_RESULT: false });

    const fetchDhsBackgroundCheckStatus = async () => {
        const result =
            await api?.dhsBackgroundCheckControllerGetDhsBackgroundCheck({
                profileId: selection.id,
            });

        setDhsBackgroundCheckStatus(result);
    };

    const fetchAndSuppress404 = async (doFetch: () => Promise<void>) => {
        try {
            await doFetch();
        } catch (e: any) {
            if (!e.statusCode || e.statusCode !== 404) {
                throw e;
            }
        }
    };

    const fetchDhsDocuments = async () => {
        await fetchAndSuppress404(async () => {
            const letter =
                await api?.dhsBackgroundCheckControllerGetDhsDocument({
                    profileId: selection.id,
                    documentType: 'DHS_CLEARANCE_LETTER',
                });

            if (letter) {
                setDhsClearanceLetter([
                    {
                        uid: letter.id,
                        name: letter.fileName,
                        url: letter.url,
                        status: 'done',
                    },
                ]);
            }
        });
        await fetchAndSuppress404(async () => {
            const result =
                await api?.dhsBackgroundCheckControllerGetDhsDocument({
                    profileId: selection.id,
                    documentType: 'DHS_REGISTRY_CHECK_RESULT',
                });

            if (result) {
                setDhsRegistryCheckResult([
                    {
                        uid: result.id,
                        name: result.fileName,
                        url: result.url,
                        status: 'done',
                    },
                ]);
            }
        });
    };

    useEffect(() => {
        if (api) {
            void fetchDhsBackgroundCheckStatus();
            void fetchDhsDocuments();
        }
    }, [api]);

    if (!api) {
        return null;
    }

    if (!dhsBackgroundCheckStatus) {
        return <Spinner />;
    }

    return (
        <>
            <Typography.Title level={4} style={{ marginTop: 10 }}>
                DHS Background Check
            </Typography.Title>
            <Form
                layout="inline"
                initialValues={{
                    status: dhsBackgroundCheckStatus.status,
                    fingerprintedAt: dhsBackgroundCheckStatus.fingerprintedAt
                        ? dayjs(dhsBackgroundCheckStatus.fingerprintedAt)
                        : null,
                }}
            >
                <Form.Item label="Status" name="status">
                    <Select
                        options={Object.entries(DhsBackgroundCheckStatus).map(
                            (entry) => ({ label: entry[0], value: entry[1] })
                        )}
                        onChange={(val) =>
                            setDhsBackgroundCheckStatus({
                                ...dhsBackgroundCheckStatus,
                                status: val,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Date of Fingerprinting"
                    name="fingerprintedAt"
                >
                    <DatePicker
                        onChange={(date) =>
                            setDhsBackgroundCheckStatus({
                                ...dhsBackgroundCheckStatus,
                                fingerprintedAt: date?.toDate() ?? null,
                            })
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <ActionButton
                        disabled={!dhsBackgroundCheckStatus.status}
                        action={async () => {
                            if (!dhsBackgroundCheckStatus.status) {
                                return;
                            }

                            await api?.dhsBackgroundCheckControllerUpdateDhsBackgroundCheck(
                                {
                                    profileId: selection.id,
                                    dhsBackgroundCheckStatusDto:
                                        dhsBackgroundCheckStatus,
                                }
                            );
                        }}
                        successMessage="DHS Background Check Status saved"
                    >
                        Save
                    </ActionButton>
                </Form.Item>
            </Form>
            <Typography.Title level={4} style={{ marginTop: 10 }}>
                DHS Clearance Letter
            </Typography.Title>
            <Upload
                fileList={dhsClearanceLetter}
                onRemove={async () => {
                    await api?.dhsBackgroundCheckControllerRemoveDhsDocument({
                        profileId: selection.id,
                        documentType: 'DHS_CLEARANCE_LETTER',
                    });

                    setDhsClearanceLetter([]);
                }}
                beforeUpload={async (file) => {
                    setIsUploading({
                        ...isUploading,
                        DHS_CLEARANCE_LETTER: true,
                    });

                    const result =
                        await api?.dhsBackgroundCheckControllerUploadDhsDocument(
                            {
                                letter: file,
                                profileId: selection.id,
                                documentType: 'DHS_CLEARANCE_LETTER',
                            }
                        );
                    setDhsClearanceLetter([
                        {
                            uid: result.id,
                            name: result.fileName,
                            url: result.url,
                            status: 'done',
                        },
                    ]);

                    setIsUploading({
                        ...isUploading,
                        DHS_CLEARANCE_LETTER: false,
                    });

                    return false;
                }}
            >
                <Button
                    icon={<FileAddOutlined />}
                    loading={isUploading.DHS_CLEARANCE_LETTER}
                >
                    Attach File
                </Button>
            </Upload>

            <Typography.Title level={4} style={{ marginTop: 10 }}>
                DHS Registry Check Result
            </Typography.Title>
            <Upload
                fileList={dhsRegistryCheckResult}
                onRemove={async () => {
                    await api?.dhsBackgroundCheckControllerRemoveDhsDocument({
                        profileId: selection.id,
                        documentType: 'DHS_REGISTRY_CHECK_RESULT',
                    });

                    setDhsRegistryCheckResult([]);
                }}
                beforeUpload={async (file) => {
                    setIsUploading({
                        ...isUploading,
                        DHS_REGISTRY_CHECK_RESULT: true,
                    });

                    const result =
                        await api?.dhsBackgroundCheckControllerUploadDhsDocument(
                            {
                                letter: file,
                                profileId: selection.id,
                                documentType: 'DHS_REGISTRY_CHECK_RESULT',
                            }
                        );
                    setDhsRegistryCheckResult([
                        {
                            uid: result.id,
                            name: result.fileName,
                            url: result.url,
                            status: 'done',
                        },
                    ]);

                    setIsUploading({
                        ...isUploading,
                        DHS_REGISTRY_CHECK_RESULT: false,
                    });

                    return false;
                }}
            >
                <Button
                    icon={<FileAddOutlined />}
                    loading={isUploading.DHS_REGISTRY_CHECK_RESULT}
                >
                    Attach File
                </Button>
            </Upload>
        </>
    );
}

export default function BgChecksTab() {
    return (
        <>
            <CheckrBackgroundChecks />
            <DhsBackgroundCheck />
        </>
    );
}

import { FileAddOutlined } from '@ant-design/icons';
import {
    Typography,
    Input,
    Space,
    List,
    Flex,
    Button,
    Upload,
    UploadFile,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import { FacilityNoteDto, ProfileNoteDto } from '@clh/api-client';

import { ActionButton } from '../action-button';
import useCurrentProfile from '../hooks/use-current-profile/use-current-profile';
import NoteListItem from '../shared/note-list-item';

export default function Notes({
    fetchNotes,
    onSubmit,
}: {
    fetchNotes: () => Promise<(ProfileNoteDto | FacilityNoteDto)[]>;
    onSubmit: (draftNote: string, files: UploadFile[]) => Promise<void>;
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [draftNote, setDraftNote] = useState<string>('');
    const notesRef = useRef<HTMLFormElement>(null);
    const [toggleEdit, setToggleEdit] = useState<Record<string, boolean>>({});
    const [notes, setNotes] = useState<(ProfileNoteDto | FacilityNoteDto)[]>();
    const [draftNoteFileList, setDraftNoteFileList] = useState<UploadFile[]>(
        []
    );

    const currentProfile = useCurrentProfile();

    const handleFetchNotes = () => {
        fetchNotes()
            .then(setNotes)
            .catch(() => {
                // TODO
            })
            .finally(() => setIsLoading(false));
    };

    const handleSubmit = async () => {
        if (!draftNote.length && !draftNoteFileList.length) {
            throw Error('No note drafted');
        }

        await onSubmit(draftNote, draftNoteFileList);

        handleFetchNotes();

        if (notesRef?.current) {
            notesRef.current.value = '';
            setDraftNote('');
            setDraftNoteFileList([]);
        }
    };

    useEffect(() => {
        handleFetchNotes();
    }, []);

    return (
        <>
            <Typography.Title level={4}>Notes</Typography.Title>
            <Space
                direction="vertical"
                size="middle"
                style={{ display: 'flex' }}
            >
                <Input.TextArea
                    ref={notesRef}
                    rows={4}
                    disabled={isLoading}
                    onChange={(evt) => setDraftNote(evt.target.value)}
                />
                <Flex justify="space-between">
                    <ActionButton
                        successMessage="Note submitted"
                        disabled={isLoading}
                        action={handleSubmit}
                    >
                        Submit
                    </ActionButton>
                    <Upload
                        listType="picture"
                        onRemove={(file) => {
                            const index = draftNoteFileList.indexOf(file);
                            const newFileList = [...draftNoteFileList];
                            newFileList.splice(index, 1);
                            setDraftNoteFileList(newFileList);
                        }}
                        beforeUpload={(file) => {
                            setDraftNoteFileList([...draftNoteFileList, file]);

                            return false;
                        }}
                        fileList={draftNoteFileList}
                    >
                        <Button icon={<FileAddOutlined />}>Attach File</Button>
                    </Upload>
                </Flex>
                {notes && (
                    <List
                        bordered
                        dataSource={notes}
                        renderItem={(item) => (
                            <NoteListItem
                                item={item}
                                isAuthor={
                                    currentProfile.profile?.id === item.authorId
                                }
                                isEditing={!!toggleEdit[item.id]}
                                fetchNotes={fetchNotes}
                                setIsEditing={(bool: boolean) => {
                                    setToggleEdit({
                                        ...toggleEdit,
                                        [item.id]: bool,
                                    });
                                }}
                            />
                        )}
                    />
                )}
            </Space>
        </>
    );
}

import { PropsWithChildren } from 'react';

import FetchRecordContext, {
    FetchRecordContextType,
} from './fetch-record-context';

export default function FetchRecordProvider<RecordType>({
    children,
    ...props
}: FetchRecordContextType<RecordType> & PropsWithChildren) {
    return (
        <FetchRecordContext.Provider value={props}>
            {children}
        </FetchRecordContext.Provider>
    );
}

import { PropsWithChildren, useRef } from 'react';

import ApiCacheContext from './api-cache-context';

export default function ApiCacheProvider({ children }: PropsWithChildren) {
    const cache = useRef<{
        [key: string]: { createdAt: number; value: any } | undefined;
    }>({});

    const get = (key: string) => {
        const { createdAt, value } = cache.current[key] || {};

        if (createdAt && createdAt > Date.now() - 1000 * 60) {
            return value;
        }

        cache.current[key] = undefined;

        return;
    };

    const set = (key: string, value: any) => {
        cache.current[key] = {
            createdAt: Date.now(),
            value,
        };
    };

    return (
        <ApiCacheContext.Provider value={{ get, set }}>
            {children}
        </ApiCacheContext.Provider>
    );
}

export function unwrapError(err) {
    if (typeof err === 'string') {
        try {
            var parsed = JSON.parse(err);
            if (parsed.message) {
                return "".concat(parsed.message || parsed.error);
            }
        }
        catch (_e) {
            return err;
        }
    }
    if (Array.isArray(err)) {
        return err.map(unwrapError).join(', ');
    }
    if (typeof err === 'object') {
        if (Object.hasOwn(err, 'message')) {
            return unwrapError(err.message);
        }
    }
    return 'Unknown error';
}

import { createContext } from 'react';

import { PaginatedResult } from '../../table/paginated-result';

export interface FetchRecordContextType<RecordType> {
    record: RecordType;
    loading: boolean;
    error: unknown;
    params?: object;
    setParams?: (p: object) => void;
    fetchRecord: (params?: object) => Promise<void>;
    meta?: PaginatedResult<RecordType>['meta'];
}

export default createContext<FetchRecordContextType<any>>(undefined as any);

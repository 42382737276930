import { Tabs as AntTabs } from 'antd';

import AssignmentTab from './assignment';
import CostsTab from './costs';
import DetailsTab from './details';
import RatingsTab from './ratings';

export default function Tabs() {
    return (
        <AntTabs
            items={[
                {
                    label: 'Details',
                    key: 'tab-details',
                    children: <DetailsTab />,
                },
                {
                    label: 'Costs',
                    key: 'tab-costs',
                    children: <CostsTab />,
                },
                {
                    label: 'Assignment',
                    key: 'tab-assignment',
                    children: <AssignmentTab />,
                },
                {
                    label: 'Ratings',
                    key: 'tab-ratings',
                    children: <RatingsTab />,
                },
            ]}
        />
    );
}

import { Form as AntForm } from 'antd';
import { FC, useState } from 'react';

import { JurisdictionDto } from '@clh/api-client';
import { useApiClient, useError } from '@clh/ui';

import { useFetchRecordContext } from '../hooks/use-fetch-record';
import { Modal } from '../modal';

import { Form } from './form';

export const Create: FC = () => {
    const [form] = AntForm.useForm();
    const api = useApiClient();
    const [open, setOpen] = useState(false);
    const [error, setError] = useError();
    const { fetchRecord } = useFetchRecordContext();

    function handleTarget() {
        form.resetFields();
        setOpen(true);
    }

    function handleOk() {
        form.submit();
    }

    async function handleFinish(jurisdiction: JurisdictionDto) {
        try {
            await api?.jurisdictionControllerCreateJurisdiction({
                jurisdictionDto: jurisdiction,
            });
            setOpen(false);
            await fetchRecord();
        } catch (e) {
            setError(e);
        }
    }

    return (
        <Modal
            open={open}
            title="Create New Jurisdiction"
            onTarget={handleTarget}
            onOk={handleOk}
            onCancel={() => setOpen(false)}
        >
            <Form form={form} error={error} onFinish={void handleFinish} />
        </Modal>
    );
};
